/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react"
import { animate, stagger } from "motion"

interface VeryProps extends React.ComponentPropsWithoutRef<"svg"> {
	withAnimation?: boolean
}

export const Very = ({ withAnimation = false, ...props }: VeryProps) => {
	const eRef = React.useRef<SVGPathElement>(null!)
	const rRef = React.useRef<SVGPathElement>(null!)
	const yRef = React.useRef<SVGPathElement>(null!)

	React.useEffect(() => {
		if (!withAnimation) return

		animate(
			[eRef.current, rRef.current, yRef.current],
			{ opacity: 1 },
			{ delay: stagger(0.3, { start: 0.5 }), duration: 0.5 },
		)
	}, [withAnimation])

	return (
		<svg
			viewBox="0 0 121 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			{withAnimation && (
				<defs>
					<linearGradient id="v-black" x1="100%" x2="100%" y1="0%" y2="100%">
						<stop offset={0} stopColor="#000">
							<animate
								dur="0.5s"
								attributeName="offset"
								fill="freeze"
								from={0}
								to={1}
							/>
						</stop>

						<stop offset={0} stopColor="#FFF">
							<animate
								dur="0.5s"
								attributeName="offset"
								fill="freeze"
								from={0}
								to={1}
							/>
						</stop>
					</linearGradient>

					<linearGradient id="v-red" x1="100%" x2="100%" y1="100%" y2="0%">
						<stop offset={0} stopColor="#EC1E27">
							<animate
								dur="0.5s"
								begin="0.5s"
								attributeName="offset"
								fill="freeze"
								from={0}
								to={1}
							/>
						</stop>

						<stop offset={0} stopColor="#FFF">
							<animate
								dur="0.5s"
								begin="0.5s"
								attributeName="offset"
								fill="freeze"
								from={0}
								to={1}
							/>
						</stop>
					</linearGradient>
				</defs>
			)}

			{/* V-black */}
			<path
				d="M17.7277 25H10.0526L0 0H7.67513L17.7277 25Z"
				fill={withAnimation ? "url(#v-black)" : "#000"}
			/>

			{/* V-Red */}
			<path
				d="M28.2535 0L22.1546 15H14.4037L20.499 0H28.2535Z"
				fill={withAnimation ? "url(#v-red)" : "#EC1E27"}
			/>

			{/* E */}
			<path
				d="M34 0V25H57V19.1916H41.043V15.1471H54.616V9.77944H41.043V5.8089H57V0H34Z"
				fill="#231F20"
				ref={eRef}
				opacity={withAnimation ? 0 : 1}
			/>

			{/* R */}
			<path
				d="M72.4414 10.6252H79.328C81.4638 10.6252 82.4581 9.85289 82.4581 8.19867C82.4581 6.54445 81.4638 5.77218 79.328 5.77218H72.4414V10.6252ZM82.6425 25L76.971 16.3606H72.4414V25H65.3709V0H80.2855C86.4358 0 89.6394 2.831 89.6394 8.19867C89.6394 12.0958 87.9457 14.6324 84.7047 15.7352L90.8545 25H82.6425Z"
				fill="#231F20"
				ref={rRef}
				opacity={withAnimation ? 0 : 1}
			/>

			{/* Y */}
			<path
				d="M110.15 13.9708V25H103.136V14.0076L93.0704 0H101.646L106.843 7.53709L112.04 0H120.216L110.15 13.9708Z"
				fill="#231F20"
				ref={yRef}
				opacity={withAnimation ? 0 : 1}
			/>
		</svg>
	)
}
