import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import clsx from "clsx"
import { isFilled } from "@prismicio/helpers"

import { BoundedBox } from "../components/BoundedBox"

import type { PageDataBodyRichTextFragment } from "../graphql.gen"
import { RichText } from "../components/RichText"

type Props = ReturnType<typeof mapDataToProps>

const variants = {
	centered: { margin: "mx-auto" },
	default: { margin: undefined },
}
type Variant = keyof typeof variants

export const PageDataBodyRichText = ({ variant, text }: Props) => {
	const styles = variants[variant]

	return (
		<BoundedBox className="bg-white">
			{isFilled.richText(text) && (
				<RichText
					field={text}
					className={clsx("max-w-[820px]", styles.margin)}
				/>
			)}
		</BoundedBox>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyRichTextFragment>) {
	return {
		text: data.primary.text?.richText,
		variant: (data.primary.centered ? "centered" : "default") as Variant,
	}
}

export const fragment = graphql`
	fragment PageDataBodyRichText on PrismicPageDataBodyRichText {
		primary {
			centered
			text {
				richText
			}
		}
	}

	fragment BlogPostDataBodyRichText on PrismicBlogPostDataBodyRichText {
		primary {
			centered
			text {
				richText
			}
		}
	}
`

export default PageDataBodyRichText
