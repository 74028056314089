import { graphql } from "gatsby"
import { PrismicLink } from "@prismicio/react"
import clsx from "clsx"
import { type IGatsbyImageData } from "gatsby-plugin-image"

import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { BoundedBox } from "../components/BoundedBox"
import { DescriptionCard } from "../components/DescriptionCard"

import type { PageDataBodyFeaturedBlogPostFragment } from "../graphql.gen"
import { HoverZoomImage } from "../components/HoverZoomImage"
import blogDefaultUrl from "../assets/blog-default.jpg"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyFeaturedBlogPost = ({
	category,
	href,
	image,
	imageAlt,
	summary,
	title,
}: Props) => {
	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner
				className="grid lg:grid-cols-[1fr,calc(474px-170px)]"
				maxWidth="narrow"
			>
				<PrismicLink
					href={href}
					className={clsx(
						"block",
						"-mx-[26px] lg:mx-0",
						"md:col-start-1 md:row-start-1",
						"group",
					)}
				>
					<div className="aspect-w-[320] aspect-h-[284] md:aspect-w-[785] md:aspect-h-[527] overflow-hidden">
						<HoverZoomImage
							image={image}
							alt={imageAlt}
							className="bg-gray-20"
							renderImage={
								!image ? (
									<img
										src={blogDefaultUrl}
										loading="lazy"
										decoding="async"
										className="object-cover h-full w-full object-center"
										alt=""
										width={1600}
										height={1073}
									/>
								) : undefined
							}
						/>
					</div>
				</PrismicLink>

				<DescriptionCard
					subheading={category}
					heading={title}
					href={href}
					richText={summary}
					linkText="Read Article"
					className={clsx(
						"-mt-[138px] md:-mt-0",
						"-mr-[26px] lg:-mr-0",
						"ml-5 md:ml-0 lg:-ml-[170px]",
						"isolate",
						"max-w-[474px]",
						"justify-self-end md:self-end",
						"md:col-start-1 lg:col-start-2",
						"md:row-start-1",
					)}
				/>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyFeaturedBlogPostFragment>) {
	const blogPost = data.primary.blog_post?.document
	if (blogPost?.__typename !== "PrismicBlogPost") {
		throw new Error("Did not receive blog_post when queried for one!")
	}

	const blogCategory = blogPost.data.category?.document
	if (blogCategory?.__typename !== "PrismicBlogCategory") {
		throw new Error("Did not receive blog_category when queried for one!")
	}

	return {
		href: blogPost.url,
		title: blogPost.data.title?.text,
		summary: blogPost.data.summary?.richText,
		imageAlt: blogPost.data.image?.alt,
		image: blogPost.data.image?.gatsbyImageData as IGatsbyImageData,
		category: blogCategory.data.name?.text,
	}
}

export const fragment = graphql`
	fragment PageDataBodyFeaturedBlogPost on PrismicPageDataBodyFeaturedBlogPost {
		primary {
			blog_post {
				document {
					... on PrismicBlogPost {
						_previewable
						__typename
						url
						data {
							image {
								alt
								gatsbyImageData(
									width: 976
									sizes: "(min-width: 90rem) 60rem, (min-width: 64rem) 60vw, 100vw"
									layout: FULL_WIDTH
								)
							}
						}
						data {
							title {
								text
							}
							summary {
								richText
							}
							category {
								document {
									... on PrismicBlogCategory {
										_previewable
										__typename
										data {
											name {
												text
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

export default PageDataBodyFeaturedBlogPost
