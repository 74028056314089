import * as React from "react"
import clsx from "clsx"
import { PrismicLink } from "@prismicio/react"
import { graphql, useStaticQuery } from "gatsby"
import debounce from "just-debounce-it"
import * as NavMenu from "@radix-ui/react-navigation-menu"

import { BoundedBox } from "./BoundedBox"
import { MenuButton } from "./MenuButton"
import { VeryLawLogo } from "./VeryLawLogo"
import { Text } from "./Text"
import { ThinChevronButton } from "./ThinChevronButton"
import { SearchInput } from "./SearchInput"
import { siteSearch } from "../lib/siteSearch"
import type { HeaderDataQuery } from "../graphql.gen"
import type { Location } from "../types"
import { SearchExpand } from "./SearchExpand"
import { safeHref } from "../lib/safeHref"
import { Button } from "./Button"

type HeaderData = ReturnType<typeof useHeaderData>
type NavItem = HeaderData["primaryMenu"][number]
type SubNavItem = NavItem["items"][number]

function useHeaderData() {
	const result = useStaticQuery<HeaderDataQuery>(graphql`
		query HeaderData {
			prismicSettings {
				data {
					primary_navigation {
						...NavigationMenuData
					}
					secondary_navigation {
						...NavigationMenuData
					}
					phone_number
				}
			}
		}
	`)

	const data = result.prismicSettings?.data
	const primaryMenu = data?.primary_navigation?.document
	const secondaryMenu = data?.secondary_navigation?.document
	if (
		primaryMenu?.__typename !== "PrismicNavigation" ||
		secondaryMenu?.__typename !== "PrismicNavigation"
	) {
		throw new Error(
			"Did not receive a PrismicNavigation when we queried for it."
		)
	}

	type Item = NonNullable<typeof primaryMenu["data"]>["body"][number]

	function mapDataToMenu(item: Item) {
		return {
			href: safeHref(item.primary.link_url?.url),
			text: item.primary.link_text,
			items: item.items.map((secondaryItem) => {
				let menu = secondaryItem.submenu?.document
				if (menu?.__typename !== "PrismicNavigation") menu = undefined

				const menuItems = menu?.data.body.map((sItem) => {
					let doc = sItem.primary.link_url?.document
					if (doc?.__typename !== "PrismicPracticeArea") doc = undefined

					const href = doc?.url ?? sItem.primary.link_url?.url

					return {
						href: safeHref(href),
						text: sItem.primary.link_text,
					}
				})

				return {
					href: safeHref(secondaryItem.link_url?.url),
					text: secondaryItem.link_text,
					menu: menuItems,
				}
			}),
		}
	}

	return {
		primaryMenu: primaryMenu.data.body.map(mapDataToMenu),
		secondaryMenu: secondaryMenu.data.body.map(mapDataToMenu),
		phoneNumber: data?.phone_number,
	}
}

interface AnimatedLogoProps {
	isOpen: boolean
}
const AnimatedLogo = ({ isOpen }: AnimatedLogoProps) => {
	return (
		<div className="relative">
			<VeryLawLogo variant="dark" className="isolate w-[236px]" />

			<div
				className={clsx(
					"absolute inset-x-0 origin-right bg-white -inset-y-1",
					"transition duration-700 ease-in-out",
					isOpen ? "scale-x-0" : "scale-x-[.86]"
				)}
			/>
		</div>
	)
}

interface NavItemProps {
	href: string
	text?: string
	showRedLine?: boolean
	onClick?: () => void
	location: Location
}
const PrimaryNavLink = ({
	href,
	text,
	showRedLine = false,
	onClick,
	location,
}: NavItemProps) => {
	const isActive = location.pathname.includes(href as string)

	return (
		<PrismicLink
			href={href === "https://#" ? "#" : href}
			className={clsx("relative block transition duration-200", "group")}
			onClick={onClick}
		>
			<div
				className={clsx(
					"hidden lg:block",
					"pointer-events-none",
					"absolute -inset-x-1.5 -bottom-2.5",
					"h-1 bg-red",
					"group-hover:scale-x-100",
					"transition origin-left duration-[400ms] ease-in-out",
					showRedLine || isActive ? "scale-x-100" : "scale-x-0"
				)}
			/>

			<Text variant="headerPrimaryItem">{text}</Text>
		</PrismicLink>
	)
}

type SubItemProps = SubNavItem & {
	onLinkClick?: () => void
}
const SubItem = ({ href, text, menu, onLinkClick }: SubItemProps) => {
	const [isOpen, setIsOpen] = React.useState(false)

	return (
		<div className="space-y-7">
			<div className="flex items-center space-x-7">
				<PrismicLink
					href={href}
					className="transition hover:text-red"
					onClick={onLinkClick}
				>
					<Text variant="headerSubItem" uppercase>
						{text}
					</Text>
				</PrismicLink>

				{menu && (
					<ThinChevronButton
						onClick={() => setIsOpen((prev) => !prev)}
						className={clsx(isOpen ? "rotate-180" : "rotate-0", "transition")}
						label={isOpen ? `Close ${text} submenu` : `Open ${text} submenu`}
					/>
				)}
			</div>

			{isOpen && (
				<nav
					aria-label={`${text} submenu`}
					className="grid gap-7 justify-items-start pl-5"
				>
					{menu?.map((sItem) => (
						<PrismicLink
							href={sItem.href}
							key={sItem.text}
							className="transition hover:text-red"
							onClick={onLinkClick}
						>
							<Text variant="headerSubItem" uppercase>
								{sItem.text}
							</Text>
						</PrismicLink>
					))}
				</nav>
			)}
		</div>
	)
}

interface NavItemWithSubmenuProps extends NavItemProps {
	navItems: SubNavItem[]
	onLinkClick?: () => void
}
const PrimaryNavItemWithSubMenu = ({
	href,
	text,
	navItems,
	location,
	onLinkClick,
}: NavItemWithSubmenuProps) => {
	const [isOpen, setIsOpen] = React.useState(false)

	return (
		<div>
			<div className="flex items-center space-x-7">
				<PrimaryNavLink
					href={href}
					text={text}
					showRedLine={isOpen}
					location={location}
					onClick={onLinkClick}
				/>

				<ThinChevronButton
					onClick={() => setIsOpen((prev) => !prev)}
					className={clsx(isOpen ? "rotate-180" : "rotate-0", "transition")}
					label={isOpen ? `Close ${text} submenu` : `Open ${text} submenu`}
				/>
			</div>

			{isOpen && (
				<nav
					aria-label={`${text} submenu`}
					className="grid mt-9 gap-7 justify-items-start"
				>
					{navItems.map((item) => (
						<SubItem key={item.text} onLinkClick={onLinkClick} {...item} />
					))}
				</nav>
			)}
		</div>
	)
}

const MobileSearch = ({ closeMenu }: { closeMenu: () => void }) => {
	function handleSubimt(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		const target = e.target as typeof e.target & {
			query: HTMLInputElement
		}

		siteSearch({ query: target.query.value })
		closeMenu()
	}

	return (
		<SearchInput
			variant="focusBorder"
			onSubmit={handleSubimt}
			name="query"
			className="-mx-3"
		/>
	)
}

const MobileMenu = ({
	primaryMenu,
	secondaryMenu,
	location,
	phoneNumber,
	closeMenu,
}: HeaderComponentProps & { closeMenu: () => void }) => {
	return (
		<div className="absolute inset-x-0 bg-white top-full px-[26px] pb-8 pt-5 max-h-[85vh] overflow-auto">
			<nav
				className="grid gap-8 justify-items-start"
				aria-label="Primary Navigation"
			>
				{primaryMenu.map((item) =>
					item.items.length > 0 ? (
						<PrimaryNavItemWithSubMenu
							key={item.text}
							href={item.href}
							navItems={item.items}
							location={location}
							text={item.text}
							onLinkClick={closeMenu}
						/>
					) : (
						<PrimaryNavLink
							key={item.text}
							href={item.href}
							text={item.text}
							location={location}
							onClick={closeMenu}
						/>
					)
				)}
			</nav>

			<nav className="grid mt-12 gap-7 justify-items-start">
				{secondaryMenu.map((item) => (
					<PrismicLink
						key={item.text}
						href={item.href}
						className="transition hover:text-red"
						onClick={closeMenu}
					>
						<Text variant="headerSecondaryItem">{item.text}</Text>
					</PrismicLink>
				))}

				<MobileSearch closeMenu={closeMenu} />

				<Button
					color="red"
					size="small"
					asChild
					suppressHydrationWarning
					className="-mt-3.5"
				>
					<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
				</Button>
			</nav>
		</div>
	)
}

interface TopStaticNavProps {
	isOpen: boolean
	toggleMenu: () => void
}
const TopStaticNav = ({ isOpen, toggleMenu }: TopStaticNavProps) => {
	return (
		<BoundedBox.Inner className="flex items-center justify-between">
			<AnimatedLogo isOpen={isOpen} />
			<MenuButton isOpen={isOpen} onClick={toggleMenu} />
		</BoundedBox.Inner>
	)
}

const MobileHeader = (props: HeaderComponentProps) => {
	const [isOpen, setIsOpen] = React.useState(false)

	function toggleMenu() {
		setIsOpen((prev) => !prev)
	}

	function closeMenu() {
		setIsOpen(false)
	}

	React.useEffect(() => {
		document.body.style.overflow = isOpen ? "hidden" : "auto"
	}, [isOpen])

	return (
		<>
			{isOpen && (
				<button
					className="bg-black/60 grayscale-[.6] fixed inset-0 lg:hidden"
					onClick={() => setIsOpen(false)}
				/>
			)}

			<BoundedBox.Outer
				asChild
				paddingY="header"
				className="relative bg-white lg:hidden"
				collapsePadding={false}
			>
				<div>
					<TopStaticNav isOpen={isOpen} toggleMenu={toggleMenu} />
					{isOpen && <MobileMenu closeMenu={closeMenu} {...props} />}
				</div>
			</BoundedBox.Outer>
		</>
	)
}

const DesktopLogo = ({ location }: { location: Location }) => {
	const [isVisible, setIsVisible] = React.useState(false)

	React.useEffect(() => {
		const animatedIntroEl = document.querySelector("[data-animated-intro]")
		if (animatedIntroEl) {
			setIsVisible(false)

			const onScroll = () => {
				if (window.scrollY === 0) return setIsVisible(false)

				setIsVisible(true)
			}

			const debouncedOnScroll = debounce(onScroll, 100, true)

			window.addEventListener("scroll", debouncedOnScroll, { passive: true })

			return () => window.removeEventListener("scroll", debouncedOnScroll)
		}

		setIsVisible(true)
	}, [location?.pathname])

	return (
		<VeryLawLogo
			variant="dark"
			className={clsx(
				"w-[270px] xl:w-[320px] 2xl:w-[360px]",
				"transition duration-700 ease-in-out",
				"transform-gpu",
				isVisible && "translate-y-0",
				!isVisible && "-translate-y-[calc(148.3px+100%)]"
			)}
		/>
	)
}

const DesktopHeader = ({
	primaryMenu,
	secondaryMenu,
	location,
	phoneNumber,
}: HeaderComponentProps) => {
	return (
		<BoundedBox.Outer
			asChild
			paddingY="header"
			className="hidden bg-white lg:block"
		>
			<div>
				<BoundedBox.Inner className="flex items-end justify-between">
					<DesktopLogo location={location} />

					<div className="flex flex-col items-end space-y-9">
						<nav
							className="grid items-center grid-flow-col gap-8 xl:gap-[42px]"
							aria-label="Secondary Navigation"
						>
							{secondaryMenu.map((item) => (
								<PrismicLink
									key={item.text}
									href={item.href}
									className="transition hover:text-red"
									suppressHydrationWarning
								>
									<Text variant="headerSecondaryItem">{item.text}</Text>
								</PrismicLink>
							))}

							<SearchExpand />

							<Button color="red" size="small" asChild suppressHydrationWarning>
								<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
							</Button>
						</nav>

						<NavMenu.Root orientation="horizontal">
							<NavMenu.List className="flex space-x-8 xl:space-x-[53px]">
								{primaryMenu.map((item) => {
									const hasSubMenu = item.items.length > 0

									return (
										<NavMenu.Item key={item.text}>
											<NavMenu.Trigger asChild>
												<PrismicLink
													href={item.href}
													className="block"
													suppressHydrationWarning
												>
													<Text variant="headerPrimaryItem">{item.text}</Text>
												</PrismicLink>
											</NavMenu.Trigger>

											{hasSubMenu && (
												<NavMenu.Content
													className={clsx(
														"absolute bg-white top-[calc(100%+1rem)] left-0 shadow-md py-3.5 max-w-[15rem] w-full z-10",
														"data-[state=open]:animate-fadeIn",
														"data-[state=closed]:animate-fadeOut"
													)}
												>
													<NavMenu.Sub
														orientation="vertical"
														className="grid justify-items-start gap-y-3"
													>
														{item.items.map((item) => (
															<NavMenu.Item
																key={item.text}
																className={clsx(
																	"relative",
																	item.menu && "w-full"
																)}
															>
																<NavMenu.Trigger asChild>
																	<PrismicLink
																		href={item.href}
																		className="transition hover:text-red focus-visible:text-red py-2 px-8 block cursor-pointer"
																	>
																		<Text uppercase variant="headerSubItem">
																			{item.text}
																		</Text>
																	</PrismicLink>
																</NavMenu.Trigger>

																{item.menu && (
																	<NavMenu.Content className="absolute bg-white shadow-md py-2 left-full top-0 z-10 max-w-[15rem] w-full">
																		<NavMenu.Sub
																			orientation="vertical"
																			className="grid justify-items-start"
																		>
																			{item.menu.map((sItem) => (
																				<NavMenu.Item key={sItem.text}>
																					<NavMenu.Link asChild>
																						<PrismicLink
																							href={sItem.href}
																							className="transition hover:text-red focus-visible:text-red py-3.5 px-7 block"
																						>
																							<Text
																								uppercase
																								variant="headerSubItem"
																							>
																								{sItem.text}
																							</Text>
																						</PrismicLink>
																					</NavMenu.Link>
																				</NavMenu.Item>
																			))}
																		</NavMenu.Sub>
																	</NavMenu.Content>
																)}
															</NavMenu.Item>
														))}
													</NavMenu.Sub>
												</NavMenu.Content>
											)}
										</NavMenu.Item>
									)
								})}

								<NavMenu.Indicator className="bg-red h-1 -bottom-2.5 data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut transition-[transform_width] duration-300" />
							</NavMenu.List>
						</NavMenu.Root>
					</div>
				</BoundedBox.Inner>
			</div>
		</BoundedBox.Outer>
	)
}

interface Props {
	location: Location
}

type HeaderComponentProps = HeaderData & Props

// TODO: Sorry this header grew out of control and should be refactored.
// Sub-menu support should be smarter but just had to get it done.
export const Header = ({ location }: Props) => {
	const data = useHeaderData()

	return (
		<header className="sticky top-0 z-10 isolate">
			<MobileHeader location={location} {...data} />
			<DesktopHeader location={location} {...data} />
		</header>
	)
}
