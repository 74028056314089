import { navigate } from "gatsby"

interface Args {
	query: string
	skip?: number
}

export function siteSearch(args: Args) {
	const skip = args.skip ?? 0
	const params = new URLSearchParams({
		query: args.query,
		skip: skip.toString(),
	})

	navigate("/search?" + params.toString())
}
