import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { isFilled } from "@prismicio/helpers"
import clsx from "clsx"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"

import { BoundedBox } from "../components/BoundedBox"
import { Text } from "../components/Text"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"

import type { PageDataBodyHeroFragment } from "../graphql.gen"
import { UnderlineLink } from "../components/UnderlineLink"

import heroBgWebp from "../assets/hero-bg.webp"
import heroBgPng from "../assets/hero-bg.png"

const HeroBg = () => {
	return (
		<picture>
			<source srcSet={heroBgWebp} type="image/webp" />
			<img
				src={heroBgPng}
				alt=""
				loading="lazy"
				decoding="async"
				className="object-cover w-full h-full"
				width={2000}
				height={1786}
			/>
		</picture>
	)
}

const Content = ({
	heading,
	links,
	richText,
	subheading,
}: Omit<HeroProps, "personImage">) => {
	return (
		<>
			<div className="space-y-4 lg:space-y-5 2xl:space-y-6">
				{heading && (
					<Text asChild variant="heading2" uppercase>
						<h2>{heading}</h2>
					</Text>
				)}

				{subheading && (
					<Text asChild variant="heading2" className="font-extrabold text-red">
						<h2>{subheading}</h2>
					</Text>
				)}
			</div>

			{isFilled.richText(richText) && (
				<div className="grid mt-6 lg:mt-8 2xl:mt-10 gap-y-6">
					<PrismicRichText
						field={richText}
						components={{
							paragraph: (props) => (
								<Text variant="paragraph1" asChild fontFamily="serif">
									<p>{props.children}</p>
								</Text>
							),
						}}
					/>
				</div>
			)}

			{links.length >= 0 && (
				<div className="grid mt-10 lg:mt-12 2xl:mt-14 justify-items-start gap-y-8 lg:gap-y-10">
					{links.map((link) => (
						<UnderlineLink href={link.href} key={link.text}>
							{link.text}
						</UnderlineLink>
					))}
				</div>
			)}
		</>
	)
}

const MobilePersonImage = ({ personImage }: Pick<HeroProps, "personImage">) => {
	return (
		<div className="relative overflow-hidden">
			<div className="bg-gray-10 aspect-w-[320] aspect-h-[269]"></div>

			<div className="absolute -bottom-[13px] right-10 -left-16 -top-6 pointer-events-none">
				<HeroBg />
			</div>

			{personImage?.gatsbyImageData && (
				<div className="absolute left-12 top-4 right-[100px] bottom-0">
					<GatsbyImage
						image={personImage?.gatsbyImageData as IGatsbyImageData}
						alt={personImage?.alt ?? ""}
						objectFit="contain"
						className="w-full h-full"
						loading="eager"
					/>
				</div>
			)}
		</div>
	)
}

const MobileHero = ({
	heading,
	links,
	personImage,
	richText,
	subheading,
}: HeroProps) => {
	return (
		<div className="md:hidden">
			<MobilePersonImage personImage={personImage} />

			<BoundedBox.Outer asChild className="bg-white">
				<div>
					<BoundedBox.Inner className="text-black">
						<Content
							heading={heading}
							links={links}
							richText={richText}
							subheading={subheading}
						/>
					</BoundedBox.Inner>
				</div>
			</BoundedBox.Outer>
		</div>
	)
}

const DesktopPersonImage = ({
	personImage,
}: Pick<HeroProps, "personImage">) => {
	return (
		<div
			className={clsx(
				"relative",
				"-my-11 lg:-my-[55px] 2xl:-my-20",
				"-ml-[26px] lg:-ml-[50px] 2xl:-ml-[69px]",
			)}
		>
			<div className="absolute inset-0 pointer-events-none">
				<HeroBg />
			</div>

			{personImage?.gatsbyImageData && (
				<div className="absolute inset-x-0 bottom-0 top-6">
					<GatsbyImage
						image={personImage?.gatsbyImageData as IGatsbyImageData}
						alt={personImage?.alt ?? ""}
						objectFit="contain"
						className="w-full h-full"
						loading="eager"
					/>
				</div>
			)}
		</div>
	)
}

const DesktopHero = ({
	heading,
	links,
	personImage,
	richText,
	subheading,
}: HeroProps) => {
	return (
		<BoundedBox.Outer asChild className="hidden bg-gray-10 md:block">
			<div>
				<BoundedBox.Inner
					className={clsx(
						"grid",
						"grid-cols-[2fr,3fr] lg:grid-cols-2",
						"gap-x-8 xl:gap-x-10 2xl:gap-x-16",
					)}
				>
					<DesktopPersonImage personImage={personImage} />

					<div className="text-white">
						<Content
							heading={heading}
							links={links}
							richText={richText}
							subheading={subheading}
						/>
					</div>
				</BoundedBox.Inner>
			</div>
		</BoundedBox.Outer>
	)
}

type HeroProps = ReturnType<typeof mapDataToProps>

export const PageDataBodyHero = (props: HeroProps) => {
	return (
		<section className="isolate bg-gray-10">
			<MobileHero {...props} />
			<DesktopHero {...props} />
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyHeroFragment>) {
	const primary = data.primary

	return {
		heading: primary.heading?.text,
		subheading: primary.subheading?.text,
		richText: primary.text?.richText,
		personImage: primary.person_image,
		links: data.items.map((item) => ({
			href: item.link_url?.url,
			text: item.link_text,
		})),
	}
}

export const fragment = graphql`
	fragment PageDataBodyHero on PrismicPageDataBodyHero {
		primary {
			heading {
				text
			}
			subheading {
				text
			}
			text {
				richText
			}
			person_image {
				alt
				gatsbyImageData(
					width: 848
					sizes: "(min-width: 100rem) 53rem, (min-width: 64rem) 48vw, (min-width: 48rem) 40vw, 72vw"
					placeholder: NONE
				)
			}
		}
		items {
			link_text
			link_url {
				url
			}
		}
	}
`

export default PageDataBodyHero
