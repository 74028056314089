import * as React from "react"
import clsx from "clsx"
import { Slot, type SlotProps } from "@radix-ui/react-slot"
import { GetVariants, twix } from "@asyarb/twix"

const button = twix({
	base: clsx(
		"inline-flex items-center justify-center",
		"font-sans leading-1_35 uppercase tracking-[.3px] font-semibold",
		"transition ease-out"
	),
	variants: {
		color: {
			white: "text-white border-white hover:bg-white hover:text-black",
			black: "text-black border-black hover:bg-black hover:text-white",
			red: "text-black border-red hover:bg-red hover:text-white",
		},
		size: {
			default: "px-7 py-[15px] border-[3px] text-18",
			small: "px-4 h-9 border-2 text-14",
		},
	},
	defaultVariants: {
		size: "default",
	},
})

interface StaticButtonProps extends SlotProps {
	asChild?: boolean
	disabled?: boolean
}
export type ButtonProps = StaticButtonProps & GetVariants<typeof button>

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ asChild, className, color, disabled, size, ...props }, ref) => {
		const Component = asChild ? Slot : "button"

		return (
			<Component
				ref={ref}
				className={clsx(className, button({ color, size }))}
				disabled={disabled}
				{...props}
			/>
		)
	}
)

Button.displayName = "Button"
