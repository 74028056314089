/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { Providers } from "./src/components/Providers"
import { Layout } from "./src/components/Layout"

import "./src/styles/global.css"

/** @type {import('gatsby').GatsbyBrowser['wrapRootElement']} */
export const wrapRootElement = ({ element }) => <Providers>{element}</Providers>

/** @type {import('gatsby').GatsbyBrowser['wrapPageElement']} */
export const wrapPageElement = ({ element, props }) => (
	<Layout location={props.location}>{element}</Layout>
)
