import { Footer } from "./Footer"
import { Header } from "./Header"
import type { Location } from "../types"

interface LayoutProps {
	children: React.ReactNode
	location: Location
}

export const Layout = ({ children, location }: LayoutProps) => {
	return (
		<>
			<Header location={location} />
			<main className="bg-white">{children}</main>
			<Footer />
		</>
	)
}
