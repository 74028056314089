import * as React from "react"
import { Slot, type SlotProps } from "@radix-ui/react-slot"
import clsx from "clsx"
import { GetVariants, twix } from "@asyarb/twix"

const outer = twix({
	base: "isolate",
	variants: {
		paddingX: {
			base: "px-[26px] lg:px-[50px] 2xl:px-[69px]",
		},
		paddingY: {
			base: "py-8 md:py-11 lg:py-[55px] 2xl:py-20",
			header: "py-6 md:py-8 lg:pt-6 lg:pb-10",
			callToAction: "py-14 2xl:py-20",
			personHero: "py-8 md:py-11 lg:py-[55px] xl:py-16",
			pageIntro: "py-6 md:py-9 lg:py-15 xl:py-[42px]",
			blogHighlights: "py-12 md:py-14 lg:py-20",
			animatedIntro: "py-0",
			twoColumnText: "pb-40 pt-8 md:pt-11 lg:pt-[120px] lg:pb-[180px]",
		},
	},
	defaultVariants: {
		paddingX: "base",
		paddingY: "base",
	},
})

interface StaticBoundedBoxOuterProps extends SlotProps {
	asChild?: boolean
	/** Collapses padding on similar colored BoundedBox elements */
	collapsePadding?: boolean
}
type BoundedBoxOuterProps = StaticBoundedBoxOuterProps &
	GetVariants<typeof outer>

const BoundedBoxOuter = React.forwardRef<HTMLDivElement, BoundedBoxOuterProps>(
	(
		{
			asChild,
			paddingX,
			paddingY,
			className,
			collapsePadding = true,
			...props
		},
		ref
	) => {
		const Component = asChild ? Slot : "section"

		return (
			<Component
				data-with-collapse={collapsePadding}
				className={clsx(outer({ paddingX, paddingY }), className)}
				ref={ref}
				{...props}
			/>
		)
	}
)
BoundedBoxOuter.displayName = "BoundedBox.Outer"

const inner = twix({
	base: "mx-auto w-full",
	variants: {
		maxWidth: {
			none: "max-w-none",
			base: "max-w-[1600px]",
			narrow: "max-w-[1280px]",
		},
	},
	defaultVariants: {
		maxWidth: "base",
	},
})

interface StaticBoundedBoxInnerProps extends SlotProps {
	asChild?: boolean
}
type BoundedBoxInnerPropVariants = GetVariants<typeof inner>
type BoundedBoxInnerProps = StaticBoundedBoxInnerProps &
	BoundedBoxInnerPropVariants

const BoundedBoxInner = React.forwardRef<HTMLDivElement, BoundedBoxInnerProps>(
	({ asChild, className, maxWidth, ...props }, ref) => {
		const Component = asChild ? Slot : "div"

		return (
			<Component
				className={clsx(inner({ maxWidth }), className)}
				ref={ref}
				{...props}
			/>
		)
	}
)
BoundedBoxInner.displayName = "BoundedBox.Inner"

type BoundedBoxProps = Omit<BoundedBoxOuterProps, "asChild"> &
	BoundedBoxInnerPropVariants

/**
 * Component standard bounded width box. Use this to constrain the width of
 * blocks/slices.
 *
 * If more granular control is needed, like needing to apply classes to the
 * individual elements or forwarding refs, use `<BoundedBox.Outer>` and
 * `<BoundedBox.Inner>` separately.
 */
export const BoundedBox = ({
	children,
	maxWidth,
	...props
}: BoundedBoxProps) => {
	return (
		<BoundedBoxOuter {...props}>
			<BoundedBoxInner maxWidth={maxWidth}>{children}</BoundedBoxInner>
		</BoundedBoxOuter>
	)
}

BoundedBox.Outer = BoundedBoxOuter
BoundedBox.Inner = BoundedBoxInner
