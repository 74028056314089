import React from "react"

export interface ConditionalWrapProps {
	condition: boolean
	children: React.ReactNode
	wrap: (props: React.ReactNode) => React.ReactElement
}

export const ConditionalWrap = ({
	condition,
	children,
	wrap,
}: ConditionalWrapProps) => <>{condition ? wrap(children) : children}</>
