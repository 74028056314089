import clsx from "clsx"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import { isFilled } from "@prismicio/helpers"
import { type PrismicRichTextProps } from "@prismicio/react"

import { BoundedBox } from "../components/BoundedBox"
import { HEADING_CLASSES, RichText } from "./RichText"
import { Text } from "./Text"

import personHeroSvgUrl from "../assets/person-hero-bg.svg"

interface PersonHeroProps {
	image?: IGatsbyImageData
	title?: string
	name?: string
	bio?: PrismicRichTextProps["field"]
}

// Max-w-531px
export const PersonHero = ({ image, title, name, bio }: PersonHeroProps) => {
	return (
		<section
			className={clsx(
				"grid relative overflow-hidden bg-gray-84",
				"md:grid-cols-[2fr,3fr] xl:grid-cols-[3fr,4.25fr]",
				"md:gap-x-8 lg:gap-x-12 xl:gap-x-16",
				"md:h-[37.5rem] lg:h-[43.75rem] xl:h-[calc(100svh-12rem)]"
			)}
		>
			<div className="relative md:static pt-6 md:flex md:flex-col md:justify-end">
				<img
					className={clsx(
						"inset-0 absolute max-w-none pointer-events-none bg-white object-cover object-center",
						"w-[284vw] md:w-[200vw] lg:w-[140vw]",
						"-left-[128vw] md:-left-[16vw] lg:-left-[12vw]",
						"-top-[22vw] md:-top-[16vw] lg:-top-[11vw]"
					)}
					src={personHeroSvgUrl}
					alt="Advocacy"
					loading="eager"
					width={1874}
					height={1141}
				/>

				{image && (
					<div className="w-full h-[25rem] relative md:h-full">
						<GatsbyImage
							image={image}
							alt={name ?? ""}
							className="w-full h-full [&>img]:!object-contain md:[&>img]:!object-cover !absolute inset-0 xl:[&>img]:!object-contain [&>img]:!object-top md:[&>img]:!object-left-top xl:[&>img]:!object-right-top"
							loading="eager"
						/>
					</div>
				)}
			</div>

			<div className="md:self-center relative md:mr-[26px] lg:mr-[50px] 2xl:mr-[69px] md:max-w-[658px]">
				<BoundedBox.Outer
					paddingY="personHero"
					className="bg-white overscroll-contain md:self-center md:overflow-auto md:max-h-[31.5rem] lg:max-h-[37.5rem]"
					asChild
				>
					<div>
						<BoundedBox.Inner>
							<div className="space-y-3.5 lg:space-y-5">
								{title && (
									<Text variant="heading4" asChild uppercase>
										<p>{title}</p>
									</Text>
								)}

								{name && (
									<Text variant="heading3" asChild>
										<h3>{name}</h3>
									</Text>
								)}
							</div>

							{isFilled.richText(bio) && (
								<RichText
									field={bio}
									className="mt-7 lg:mt-12"
									componentOverrides={{
										heading4: (props) => (
											<Text
												asChild
												variant="heading4"
												className={HEADING_CLASSES}
												uppercase
											>
												<h4>{props.children}</h4>
											</Text>
										),
									}}
								/>
							)}
						</BoundedBox.Inner>
					</div>
				</BoundedBox.Outer>

				<div className="hidden h-11 lg:h-[55px] xl:h-16 bg-gradient-to-b from-white md:block absolute top-0 inset-x-0" />
				<div className="hidden h-11 lg:h-[55px] xl:h-16 bg-gradient-to-t from-white md:block absolute bottom-0 inset-x-0" />
			</div>
		</section>
	)
}
