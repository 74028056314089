import * as React from "react"

interface LawProps extends React.ComponentPropsWithoutRef<"svg"> {
	withAnimation?: boolean
}

export const Law = ({
	withAnimation: _withAnimation = false,
	...props
}: LawProps) => {
	return (
		<svg
			viewBox="0 0 219 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M50.3178 42.5767V56H0.514648V0H16.2676V42.5767H50.3178Z"
				fill="#231F20"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M218.404 0L195.907 56.0002H178.478L167.356 28.3066L156.226 56.0002H138.805L116.308 0H133.483L147.519 34.9331L158.768 6.93239L161.548 0H173.162L175.943 6.91733L187.192 34.925L201.229 0H218.404Z"
				fill="#231F20"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M70.7339 23.6444H87.671L74.725 56H57.7891L70.7339 23.6444Z"
				fill="#EC1E27"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M80.2002 0H97.4498L120.043 56H102.793L80.2002 0Z"
				fill="#231F20"
			/>
		</svg>
	)
}
