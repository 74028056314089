interface Props extends React.ComponentPropsWithoutRef<"svg"> {
	leftFillClassName?: string
	rightFillClassName?: string
}

export const V = ({
	leftFillClassName = "fill-white",
	rightFillClassName = "fill-red",
	...props
}: Props) => {
	return (
		<svg
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 375 335"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M238.344 335h-103.19L0 0h103.19l135.154 335Z"
				className={leftFillClassName}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M375 .0124512 293.814 201.189H190.638L271.775.0124512H375Z"
				className={rightFillClassName}
			/>
		</svg>
	)
}
