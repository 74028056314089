import clsx from "clsx"

export const CardGrid = ({
	className,
	children,
	...props
}: React.ComponentPropsWithoutRef<"ul">) => {
	return (
		<ul
			className={clsx(
				"grid",
				"gap-7 xl:gap-y-12 xl:gap-x-16 2xl:gap-x-[90px] 2xl:gap-y-[73px]",
				"mt-7 lg:mt-10 xl:mt-12 2xl:mt-[53px]",
				"md:grid-cols-2 lg:grid-cols-3",
				className,
			)}
			{...props}
		>
			{children}
		</ul>
	)
}

const Item = (props: React.ComponentPropsWithoutRef<"li">) => {
	return <li {...props} />
}
Item.displayName = "CardGrid.Item"

CardGrid.Item = Item
