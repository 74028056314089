/**
 * This file contains a template for all Person documents in your Prismic
 * repository. It uses Gatsby's File System Route API to automatically generate
 * a page for each document using its `url` field. The `url` field is computed
 * using your app's Link Resolver.
 *
 * This template supports Prismic previews using the `withPrismicPreview` higher
 * order component.
 *
 * @see https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api/
 */

import { graphql, HeadProps, type PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { type IGatsbyImageData } from "gatsby-plugin-image"

import { PersonHero } from "../components/PersonHero"
import { BoundedBox } from "../components/BoundedBox"
import { Blockquote } from "../components/Blockquote"
import { UnderlineLink } from "../components/UnderlineLink"
import { ExpandableSections } from "../components/ExpandableSections"
import { SEO } from "../components/SEO"
import { usePersonSettings } from "../hooks/usePersonSettings"
import { useSiteSettings } from "../hooks/useSiteSettings"
import PageDataBodyCallToAction from "../slices/PageDataBodyCallToAction"

import type { PersonTemplateQuery } from "../graphql.gen"
import { jsonParseSafe } from "../lib/jsonParseSafe"

const PersonTemplate = ({ data }: PageProps<PersonTemplateQuery>) => {
	const personSettings = usePersonSettings()

	const person = data.prismicPerson?.data
	const quote = data.prismicPerson?.data.quote?.text
	const accordions = data.prismicPerson?.data.body.map((a) => {
		return {
			label: a.primary?.label?.text,
			items: a.items
				?.map((item) => {
					return item.text
				})
				.filter(Boolean) as string[],
		}
	})

	if (!person)
		throw new Error("Expected Person document data but did not receive any!")

	return (
		<>
			<PersonHero
				image={person?.outline_image?.gatsbyImageData as IGatsbyImageData}
				title={person.title}
				name={person.name?.text}
				bio={person.bio?.richText}
			/>

			{quote && (
				<BoundedBox className="bg-white">
					<Blockquote
						quotee={person.quotee}
						variant="black"
						className="-ml-2.5 lg:ml-0 lg:max-w-4xl"
					>
						{quote}
					</Blockquote>
				</BoundedBox>
			)}

			<ExpandableSections>
				{accordions &&
					accordions.map((accordion) => {
						if (!accordion.label) return

						return (
							<ExpandableSections.Section
								key={accordion.label}
								heading={accordion.label}
								items={accordion.items}
							/>
						)
					})}
			</ExpandableSections>

			<BoundedBox.Outer className="text-center bg-white">
				<BoundedBox.Inner className="flex flex-col items-center">
					<UnderlineLink href="/people">
						Meet the rest of our team
					</UnderlineLink>
				</BoundedBox.Inner>
			</BoundedBox.Outer>

			<PageDataBodyCallToAction
				buttonHref={personSettings.ctaHref}
				buttonText={personSettings.ctaLinkText}
				colorTheme={personSettings.ctaColorTheme}
				heading={personSettings.ctaHeading}
				text={personSettings.ctaText}
				accordionText={[]}
			/>
		</>
	)
}

export const Head = ({ data, location }: HeadProps<PersonTemplateQuery>) => {
	const settings = useSiteSettings()
	const person = data.prismicPerson?.data
	const structuredData = jsonParseSafe(person?.structured_data?.text)

	if (!person)
		throw new Error("Expected Person document data but did not receive any!")

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle={person.name?.text}
			pathname={location.pathname}
			structuredData={structuredData}
			meta={{
				description: person.meta_description?.text,
				title: person.meta_title?.text,
			}}
			twitter={{
				username: settings.twitter.username,
				cardImageUrl: person.twitter_card?.url ?? settings.twitter.cardImageUrl,
			}}
			openGraph={{
				cardImageUrl:
					person.open_graph_image?.url ?? settings.openGraph.cardImageUrl,
			}}
		/>
	)
}

/**
 * When a Prismic preview session is active, `withPrismicPreview` will
 * automatically provide your template with updated preview content. As editors
 * edit and save content in the Prismic writing room, the page will
 * automatically refresh to display the edited content.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicpreview
 */
export default withPrismicPreview(PersonTemplate)

export const query = graphql`
	query PersonTemplate($id: String!) {
		prismicPerson(id: { eq: $id }) {
			# The _previewable field enables previews for this document.
			_previewable
			data {
				meta_description {
					text
				}
				meta_title {
					text
				}
				structured_data {
					text
				}
				open_graph_image {
					url(imgixParams: { width: 1200, height: 630, q: 75 })
				}
				twitter_card {
					url(imgixParams: { width: 800, height: 418, q: 75 })
				}

				name {
					text
				}
				title
				outline_image {
					gatsbyImageData(
						width: 609
						layout: FULL_WIDTH
						placeholder: NONE
						sizes: "(min-width: 90rem) 38rem, (min-width: 48rem) 38vw, 90vw"
					)
				}
				bio {
					richText
				}
				quote {
					text
				}
				quotee
				body {
					... on PrismicPersonDataBodyAccordion {
						id
						primary {
							label {
								text
							}
						}
						items {
							text
						}
					}
				}
			}
		}
	}
`
