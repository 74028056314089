/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with unpublished documents.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews
 */

import { componentResolverFromMap } from "gatsby-plugin-prismic-previews"

import { GATSBY_PRISMIC_REPOSITORY_NAME } from "../env"
import { linkResolver } from "./linkResolver"
import PageTemplate from "../pages/{PrismicPage.url}"
import PersonTemplate from "../pages/{PrismicPerson.url}"
import BlogPostTemplate from "../pages/{PrismicBlogPost.url}"
import PracticeAreaTemplate from "../pages/{PrismicPracticeArea.url}"

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `withPrismicPreview` and
 * `withPrismicUnpublishedPreview` higher order components.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicunpublishedpreview
 */
export const repositoryConfigs = [
	{
		repositoryName: GATSBY_PRISMIC_REPOSITORY_NAME,
		linkResolver,
		componentResolver: componentResolverFromMap({
			page: PageTemplate,
			person: PersonTemplate,
			blog_post: BlogPostTemplate,
			practice_area: PracticeAreaTemplate,
		}),
	},
]
