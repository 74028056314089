import { graphql } from "gatsby"
import { BoundedBox } from "../components/BoundedBox"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { PrismicPageDataBodyHeadingWithAccordions } from "../graphql.gen"
import { Text } from "../components/Text"
import { isFilled } from "@prismicio/helpers"
import { PrismicRichText } from "@prismicio/react"
import { Accordion } from "../components/Accordion"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyHeadingWithAccordions = ({
	copyText,
	accordions,
}: Props) => {
	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner className="flex flex-col">
				{isFilled.richText(copyText) && (
					<div className="max-w-[53.125rem] flex flex-col gap-6">
						<PrismicRichText
							field={copyText}
							components={{
								heading2: (props) => (
									<Text
										variant="heading2"
										uppercase
										className="[&>strong]:text-red [&>strong]:font-semibold"
									>
										{props.children}
									</Text>
								),
								paragraph: (props) => (
									<Text asChild variant="paragraph1" fontFamily="serif">
										<p>{props.children}</p>
									</Text>
								),
							}}
						/>
					</div>
				)}

				<div className="grid md:grid-cols-2 xl:grid-cols-3 gap-8 w-full mt-20 items-start">
					{accordions.map((accordion, idx) => (
						<Accordion
							key={idx}
							title={accordion.title}
							description={accordion.description}
						/>
					))}
				</div>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PrismicPageDataBodyHeadingWithAccordions>) {
	const primary = data.primary

	return {
		copyText: primary.copy_text?.richText,
		accordions: data.items.map((item) => ({
			title: item.accordion_title?.text,
			description: item.accordion_description?.richText,
		})),
	}
}

export const fragment = graphql`
	fragment PageDataBodyHeadingWithAccordions on PrismicPageDataBodyHeadingWithAccordions {
		primary {
			copy_text {
				richText
			}
		}
		items {
			accordion_title {
				text
			}
			accordion_description {
				richText
			}
		}
	}
`

export default PageDataBodyHeadingWithAccordions
