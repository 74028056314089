import clsx from "clsx"
import { ThinChevronIcon } from "./ThinChevronIcon"

interface ThinChevronButtonProps
	extends React.ComponentPropsWithoutRef<"button"> {
	label: string
}

export const ThinChevronButton = ({
	className,
	label,
	...props
}: ThinChevronButtonProps) => {
	return (
		<button
			className={clsx(
				"flex items-center justify-center",
				"w-10 h-10 -m-3",
				"text-black transition hover:text-red",
				className,
			)}
			{...props}
		>
			<span className="sr-only">{label}</span>

			<ThinChevronIcon className="w-4 fill-white" />
		</button>
	)
}
