import { SearchButton } from "./SearchButton"
import { siteSearch } from "../lib/siteSearch"
import { useState } from "react"
import { SearchInput } from "./SearchInput"

export const SearchExpand = () => {
	const [expanded, setExpanded] = useState(false)

	async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		const target = e.target as typeof e.target & {
			query: HTMLInputElement
		}

		siteSearch({ query: target.query.value, skip: 0 })
		setExpanded(false)
	}

	return (
		<>
			{expanded ? (
				<SearchInput
					variant="smallInput"
					onSubmit={handleSubmit}
					onSearchIconClick={() => setExpanded(false)}
				/>
			) : (
				<SearchButton onClick={() => setExpanded(!expanded)} />
			)}
		</>
	)
}
