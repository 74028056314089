import * as React from "react"

interface Props
	extends Omit<
		React.ComponentPropsWithoutRef<"div">,
		"dangerouslySetInnerHTML" | "children"
	> {
	html: string
}

/**
 * HACK: Component for embedding raw HTML that executes inner script tags.
 * Internally, `dangerouslySetInnerHTML` just sets the `innerHTML` property
 * which can prevent internal `<script>` tags from being loaded and/or executed.
 *
 * To circumvent this, we take the HTML, create actual DOM nodes, then use
 * `appendChild` to add it to the DOM.
 */
export const RawHTMLContent = ({ html, ...props }: Props) => {
	const rDiv = React.useRef<HTMLDivElement>(null)

	React.useEffect(() => {
		if (!rDiv.current) return
		const div = rDiv.current

		const slotHtml = document.createRange().createContextualFragment(html)
		div.innerHTML = ""
		div.appendChild(slotHtml)

		// HACK: Some <script> tags naively (looking at you BambooHR) hook into
		// the `readystatechange` event to performn initialization.
		//
		// This is incredibly hacky, but we'll just fire off this event ourselves
		// after a short delay to emulate this happening during client-side routing.
		const timeout = setTimeout(() => {
			const event = new Event("readystatechange")
			document.dispatchEvent(event)
		}, 250)

		return () => clearTimeout(timeout)
	}, [html])

	return <div ref={rDiv} {...props} />
}
