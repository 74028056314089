/**
 * This file contains a template for all Blog Post documents in your Prismic
 * repository. It uses Gatsby's File System Route API to automatically generate
 * a page for each document using its `url` field. The `url` field is computed
 * using your app's Link Resolver.
 *
 * This template supports Prismic previews using the `withPrismicPreview` higher
 * order component.
 *
 * @see https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api/
 */

import { isFilled } from "@prismicio/helpers"
import { PrismicRichText } from "@prismicio/react"
import { graphql, HeadProps, type PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import clsx from "clsx"
import type { RichTextField } from "@prismicio/types"
import { type IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"

import { BoundedBox } from "../components/BoundedBox"
import { MapSlicesToComponents } from "../components/MapSlicesToComponents"
import { Text } from "../components/Text"
import { UnderlineLink } from "../components/UnderlineLink"
import { SEO } from "../components/SEO"

import { useSiteSettings } from "../hooks/useSiteSettings"
import { useBlogSettings } from "../hooks/useBlogSettings"

import PageDataBodyCallToAction from "../slices/PageDataBodyCallToAction"
import PageDataBodyPageIntro from "../slices/PageDataBodyPageIntro"

import type { BlogPostTemplateQuery } from "../graphql.gen"
import { jsonParseSafe } from "../lib/jsonParseSafe"

const dateFormatter = new Intl.DateTimeFormat("en-us", {
	month: "numeric",
	day: "numeric",
	year: "numeric",
})

interface AuthorHighlightProps {
	href?: string
	name?: string
	title?: string
	image?: IGatsbyImageData
	bio?: string
}

const AuthorHighlight = ({
	href,
	name,
	title,
	image,
	bio,
}: AuthorHighlightProps) => {
	return (
		<BoundedBox className="bg-white">
			<div
				className={clsx(
					"grid",
					"gap-y-5 sm:gap-y-3",
					"max-w-[527px]",
					"sm:grid-cols-[auto,1fr] sm:gap-x-6"
				)}
			>
				{image && (
					<GatsbyImage
						image={image}
						alt={name ?? ""}
						className="mx-auto rounded-full shrink-0 sm:self-center bg-gray-93 max-w-[9.5rem]"
						objectFit="cover"
					/>
				)}

				<div
					className={clsx(
						"py-2",
						"pl-3 sm:pl-6",
						"border-red",
						"border-l-2 sm:border-l-[3px]"
					)}
				>
					{name && (
						<Text asChild variant="authorName">
							<h3>{name}</h3>
						</Text>
					)}

					{title && (
						<Text asChild variant="authorTitle" uppercase className="mt-3">
							<h4>{title}</h4>
						</Text>
					)}

					{bio && (
						<Text
							asChild
							variant="authorBio"
							fontFamily="serif"
							className="mt-5"
						>
							<p>{bio}</p>
						</Text>
					)}
				</div>

				<UnderlineLink
					href={href}
					className="ml-3.5 mr-auto sm:col-start-2 sm:ml-[27px]"
				>
					Read Full Bio
				</UnderlineLink>
			</div>
		</BoundedBox>
	)
}

interface BlogIntroProps {
	category?: string
	title?: string
	publishDateParts?: Intl.DateTimeFormatPart[]
	summary?: RichTextField
}

const BlogIntro = ({
	category,
	publishDateParts,
	summary,
	title,
}: BlogIntroProps) => {
	return (
		<BoundedBox className="bg-white">
			<div className="max-w-[820px]">
				{category && (
					<Text asChild uppercase variant="heading4">
						<h4>{category}</h4>
					</Text>
				)}

				{title && (
					<Text asChild variant="blogTitle" className="mt-4 md:mt-5 lg:mt-6">
						<h1>{title}</h1>
					</Text>
				)}

				{publishDateParts && (
					<Text
						asChild
						variant="heading4"
						className="mt-8 font-extrabold text-gray-72"
					>
						<p>
							{publishDateParts[0].value}.{publishDateParts[1].value}.
							{publishDateParts[2].value}
						</p>
					</Text>
				)}

				{isFilled.richText(summary) && (
					<div className="mt-10 lg:mt-12">
						<PrismicRichText
							field={summary}
							components={{
								paragraph: (props) => (
									<Text
										variant="richTextLargeParagraph"
										asChild
										fontFamily="serif"
										className="font-bold"
									>
										<p>{props.children}</p>
									</Text>
								),
							}}
						/>
					</div>
				)}
			</div>
		</BoundedBox>
	)
}

const BlogPostTemplate = ({
	data,
	location,
}: PageProps<BlogPostTemplateQuery>) => {
	const blogSettings = useBlogSettings()
	const post = data.prismicBlogPost

	const categoryDoc = post?.data.category?.document
	if (categoryDoc && categoryDoc.__typename !== "PrismicBlogCategory") {
		throw new Error("Did not receive Blog Category doc when expected!")
	}

	const authorDoc = post?.data.author?.document
	if (authorDoc && authorDoc?.__typename !== "PrismicPerson") {
		throw new Error("Did not receive Person doc when expected!")
	}

	const category = categoryDoc?.data.name?.text
	const title = post?.data.title?.text
	const summary = post?.data.summary?.richText
	const publishedAt = post?.data.publish_date
	const publishDateParts = publishedAt
		? dateFormatter
				.formatToParts(new Date(publishedAt))
				.filter((part) => part.type !== "literal")
		: undefined

	const authorName = authorDoc?.data.name?.text
	const authorTitle = authorDoc?.data.title
	const authorHref = authorDoc?.url
	const authorImage = authorDoc?.data.headshot
		?.gatsbyImageData as IGatsbyImageData
	const authorBio = authorDoc?.data.short_bio?.text

	return (
		<>
			<PageDataBodyPageIntro heading="Blog" />

			<BlogIntro
				category={category}
				summary={summary}
				title={title}
				publishDateParts={publishDateParts}
			/>

			<MapSlicesToComponents slices={post?.data.body} location={location} />

			{authorDoc && (
				<AuthorHighlight
					image={authorImage}
					name={authorName}
					href={authorHref}
					title={authorTitle}
					bio={authorBio}
				/>
			)}

			<PageDataBodyCallToAction
				buttonHref={blogSettings.ctaHref}
				buttonText={blogSettings.ctaLinkText}
				colorTheme={blogSettings.ctaColorTheme}
				heading={blogSettings.ctaHeading}
				text={blogSettings.ctaText}
				accordionText={[]}
			/>
		</>
	)
}

export const Head = ({ data, location }: HeadProps<BlogPostTemplateQuery>) => {
	const settings = useSiteSettings()
	const post = data.prismicBlogPost
	const structuredData = jsonParseSafe(post?.data.structured_data?.text)

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle={post?.data.title?.text}
			pathname={location.pathname}
			structuredData={structuredData}
			meta={{
				description: post?.data.meta_description?.text,
				title: post?.data.meta_title?.text,
			}}
			twitter={{
				username: settings.twitter.username,
				cardImageUrl:
					post?.data.twitter_card?.url ?? settings.twitter.cardImageUrl,
			}}
			openGraph={{
				cardImageUrl:
					post?.data.open_graph_image?.url ?? settings.openGraph.cardImageUrl,
			}}
		/>
	)
}

/**
 * When a Prismic preview session is active, `withPrismicPreview` will
 * automatically provide your template with updated preview content. As editors
 * edit and save content in the Prismic writing room, the page will
 * automatically refresh to display the edited content.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicpreview
 */
export default withPrismicPreview(BlogPostTemplate)

export const query = graphql`
	query BlogPostTemplate($id: String!) {
		prismicBlogPost(id: { eq: $id }) {
			# The _previewable field enables previews for this document.
			_previewable
			url
			data {
				meta_description {
					text
				}
				meta_title {
					text
				}
				structured_data {
					text
				}
				twitter_card {
					url(imgixParams: { width: 800, height: 418, q: 75 })
				}
				open_graph_image {
					url(imgixParams: { width: 1200, height: 630, q: 75 })
				}

				category {
					document {
						__typename
						... on PrismicBlogCategory {
							_previewable
							data {
								name {
									text
								}
							}
						}
					}
				}
				title {
					text
				}
				publish_date
				summary {
					richText
				}

				author {
					document {
						__typename
						... on PrismicPerson {
							_previewable
							url
							data {
								name {
									text
								}
								short_bio {
									text
								}
								title
								headshot {
									gatsbyImageData(
										width: 152
										aspectRatio: 1
										layout: CONSTRAINED
										imgixParams: { crop: "faces,top" }
									)
								}
							}
						}
					}
				}

				body {
					__typename
					...BlogPostDataBody
				}
			}
		}
	}
`
