import * as React from "react"
import clsx from "clsx"
import { BoundedBox } from "./BoundedBox"
import { List } from "./List"
import { Text } from "./Text"
import { ThinChevronIcon } from "./ThinChevronIcon"

interface SectionProps {
	heading: string
	items: string[]
}

const Section = ({ heading, items = [] }: SectionProps) => {
	const [isOpen, setIsOpen] = React.useState(false)

	return (
		<details>
			<summary
				onClick={() => setIsOpen((prev) => !prev)}
				className={clsx(
					"pb-3 lg:pb-4 2xl:pb-5",
					"pr-5",
					"border-gray-84 border-b",
					"transition",
					"hover:text-red hover:border-red",
					"focus:text-red focus:border-red",
					"focus:outline-none",
				)}
			>
				<div className={clsx("flex items-center justify-between")}>
					<Text variant="heading3">{heading}</Text>
					<ThinChevronIcon
						className={clsx(
							"w-4 transition-transform origin-center",
							isOpen && "rotate-180",
						)}
					/>
				</div>
			</summary>

			<List className="pb-3 pt-9">
				{items.map((item) => (
					<List.Item key={item}>{item}</List.Item>
				))}
			</List>
		</details>
	)
}

interface ExpandableSectionsProps {
	children: React.ReactNode
}

export const ExpandableSections = ({ children }: ExpandableSectionsProps) => {
	return (
		<BoundedBox.Outer className="text-white bg-black">
			<BoundedBox.Inner className="grid gap-7 lg:gap-10 2xl:gap-12">
				{children}
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

ExpandableSections.Section = Section
