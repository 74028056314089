import * as React from "react"
import clsx from "clsx"
import { isFilled } from "@prismicio/helpers"
import { PrismicLink, PrismicRichText } from "@prismicio/react"
import { graphql } from "gatsby"
import { BoundedBox } from "../components/BoundedBox"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { Text } from "../components/Text"
import { Button, type ButtonProps } from "../components/Button"
import * as Collapsible from "@radix-ui/react-collapsible"

import type { PageDataBodyCallToActionFragment } from "../graphql.gen"
import { V } from "../components/V"
import { PlusIcon } from "../components/PlusIcon"
import { MinusIcon } from "../components/MinusIcon"
import { FilledLinkToDocumentField } from "@prismicio/types"
import { linkResolver } from "../prismic/linkResolver"

interface SharedProps {
	theme: Theme
}

const AccentBg = ({ theme }: SharedProps) => {
	return (
		<div
			className={clsx(
				theme.accentBg,
				"hidden lg:block",
				"lg:-my-14 2xl:-my-20",
				"absolute inset-y-0 pointer-events-none",
				// Add an additional 2px of adjustment to account for sub-pixel
				// overlaps in non-retina displays.
				"lg:left-[calc(60%+4rem+2px)]",
				"right-[-25vw]",
				"origin-top skew-x-[22deg]"
			)}
		/>
	)
}

const VLogo = ({ theme }: SharedProps) => {
	return (
		<div
			className={clsx(
				"absolute",
				theme.opacity,
				"inset-y-0 -inset-x-7",
				"lg:left-[calc(60%+4rem)]",
				"lg:right-unset",
				"lg:-my-14 2xl:-my-20",
				"pointer-events-none"
			)}
		>
			<V
				className={clsx(
					"min-h-full",
					"max-h-[150%] md:max-h-[125%] lg:max-h-full",
					"mx-auto lg:mx-0",
					"isolate"
				)}
				rightFillClassName={theme.rightFillClassName}
			/>
		</div>
	)
}

const themes = {
	red: {
		primaryBg: "bg-red",
		accentBg: "bg-black",
		textColor: "text-black",
		buttonColor: "black" as ButtonProps["color"],
		rightFillClassName: "fill-white lg:fill-red",
		opacity: "opacity-60 lg:opacity-100",
	},
	black: {
		primaryBg: "bg-black",
		accentBg: "bg-red",
		textColor: "text-white",
		buttonColor: "white" as ButtonProps["color"],
		rightFillClassName: "fill-red lg:fill-black",
		opacity: "opacity-[.28] lg:opacity-100",
	},
}
type Themes = typeof themes
export type ColorTheme = keyof Themes
type Theme = Themes[ColorTheme]

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyCallToAction = ({
	buttonHref,
	buttonText,
	heading,
	text,
	colorTheme,
	accordionText,
}: Props) => {
	const theme = themes[colorTheme]
	const [open, setOpen] = React.useState(false)

	return (
		<BoundedBox.Outer
			className={clsx(
				theme.textColor,
				theme.primaryBg,
				"relative overflow-hidden",
				"text-center lg:text-left"
			)}
			paddingY="callToAction"
		>
			<BoundedBox.Inner className="lg:relative">
				<AccentBg theme={theme} />
				<VLogo theme={theme} />

				<div className="isolate lg:max-w-[60%]">
					{heading && (
						<Text variant="heading2" uppercase>
							{heading}
						</Text>
					)}

					{isFilled.richText(text) && (
						<div
							className={clsx(
								"mt-10 mx-auto lg:mx-0 lg:mt-12",
								!isFilled.richText(accordionText) && "max-w-[450px]"
							)}
						>
							<PrismicRichText
								field={text}
								components={{
									paragraph: (props) => (
										<Text asChild variant="ctaParagraph" fontFamily="serif">
											<p>{props.children}</p>
										</Text>
									),
								}}
							/>
						</div>
					)}

					{isFilled.richText(accordionText) && (
						<Collapsible.Root open={open} onOpenChange={setOpen}>
							<Collapsible.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden transition-all">
								<div className="pb-4">
									<PrismicRichText
										field={accordionText}
										components={{
											paragraph: (props) => (
												<Text
													asChild
													variant="ctaParagraph"
													fontFamily="serif"
													className="mt-10"
												>
													<p>{props.children}</p>
												</Text>
											),
											hyperlink: (props) => (
												<PrismicLink
													className="transition hover:text-red underline"
													href={
														props.node.data.url ??
														linkResolver(
															props.node.data as FilledLinkToDocumentField
														)
													}
												>
													{props.children}
												</PrismicLink>
											),
										}}
									/>
								</div>
							</Collapsible.Content>

							<Collapsible.Trigger asChild className="mt-10">
								<button className="flex items-center gap-2.5 border-b-2 pb-2 border-red">
									<Text
										variant="paragraph1"
										className="text-white font-semibold"
									>
										Read More
									</Text>
									{open ? (
										<MinusIcon className="w-3.5 text-white" />
									) : (
										<PlusIcon className="w-3.5 text-white" />
									)}
								</button>
							</Collapsible.Trigger>
						</Collapsible.Root>
					)}

					{buttonText && buttonHref && (
						<Button
							color={theme.buttonColor}
							asChild
							className="mt-12 lg:mt-16 2xl:mt-[72px]"
						>
							<PrismicLink href={buttonHref}>{buttonText}</PrismicLink>
						</Button>
					)}
				</div>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyCallToActionFragment>) {
	const primary = data.primary
	const colorTheme =
		(primary.color_theme?.toLowerCase() as ColorTheme) ?? "black"

	return {
		heading: primary.heading?.text,
		text: primary.text?.richText,
		buttonHref: primary.button_link?.url,
		buttonText: primary.button_text,
		colorTheme,
		accordionText: primary.accordion_text?.richText,
	}
}

export const fragment = graphql`
	fragment PageDataBodyCallToAction on PrismicPageDataBodyCallToAction {
		primary {
			heading {
				text
			}
			text {
				richText
			}
			button_link {
				url
			}
			button_text
			color_theme
			accordion_text {
				richText
			}
		}
	}
`

export default PageDataBodyCallToAction
