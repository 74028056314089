import clsx from "clsx"
import { Text } from "./Text"
import * as styles from "./List.module.css"

type ListVariant = "ordered" | "unordered"

interface ItemProps extends React.ComponentPropsWithoutRef<"li"> {
	variant?: ListVariant
}

const Item = ({
	className,
	children,
	variant = "unordered",
	...props
}: ItemProps) => {
	return (
		<li className={clsx("flex space-x-3", className)} {...props}>
			{variant === "unordered" && (
				<div
					className={clsx(
						"bg-red w-[5px] h-[5px] rounded-full shrink-0 relative",
						"top-[3px] md:top-[3.5px] lg:top-1 2xl:top-[5px]",
					)}
				/>
			)}

			<Text variant="listItem" fontFamily="serif">
				{children}
			</Text>
		</li>
	)
}

interface ListProps {
	variant?: ListVariant
	children: React.ReactNode
	className?: string
}

export const List = ({
	className,
	variant = "unordered",
	children,
}: ListProps) => {
	const isUnordered = variant === "unordered"
	const Element = isUnordered ? "ul" : "ol"

	return (
		<Element
			className={clsx("grid gap-6", !isUnordered && styles.ol, className)}
		>
			{children}
		</Element>
	)
}

List.Item = Item
