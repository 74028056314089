import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { type IGatsbyImageData } from "gatsby-plugin-image"
import { PrismicLink } from "@prismicio/react"
import clsx from "clsx"

import { BoundedBox } from "../components/BoundedBox"
import { Text } from "../components/Text"
import { UnderlineLink } from "../components/UnderlineLink"
import { HoverZoomImage } from "../components/HoverZoomImage"

import type { PageDataBodyBlogHighlightsFragment } from "../graphql.gen"
import blogHighlightUrl from "../assets/blog-highlight-bg.jpg"

interface FeaturedPostProps {
	href?: string
	title?: string
	category?: string
	imageAlt?: string
	image?: IGatsbyImageData
}

export const FeaturedPost = ({
	category,
	href,
	image,
	imageAlt,
	title,
}: FeaturedPostProps) => {
	return (
		<div className="grid lg:contents">
			{image && (
				<PrismicLink
					href={href}
					className={clsx("-mx-[26px] lg:mx-0", "row-start-2 peer group")}
				>
					<div
						className={clsx(
							"aspect-w-[320] aspect-h-[216]",
							"md:aspect-w-[320] md:aspect-h-[180]",
							"lg:aspect-w-[508] lg:aspect-h-[341]",
							"overflow-hidden",
						)}
					>
						<HoverZoomImage
							image={image}
							alt={imageAlt}
							className="bg-gray-93"
						/>
					</div>
				</PrismicLink>
			)}

			<div
				className={clsx(
					"flex flex-col",
					"isolate",
					"bg-white max-w-[240px] md:max-w-[297px]",
					"peer-hover:text-red",
					"px-[26px]",
					"py-5 lg:py-8",
					"-mb-[71px] lg:-mb-[94px]",
					"-ml-[26px] lg:ml-0",
					"lg:row-start-1",
					"lg:mt-auto",
				)}
			>
				{category && (
					<Text asChild variant="heading5" uppercase className="text-black">
						<h5>{category}</h5>
					</Text>
				)}

				{title && (
					<Text
						variant="heading3"
						className="mt-3 transition lg:mt-5 hover:text-red"
					>
						<PrismicLink href={href}>{title}</PrismicLink>
					</Text>
				)}

				<UnderlineLink href={href} className="mr-auto text-black mt-7 lg:mt-9">
					Read Article
				</UnderlineLink>
			</div>
		</div>
	)
}

interface PostListProps {
	posts: Array<{
		href?: string
		title?: string
		category?: string
	}>
}

export const PostList = ({ posts }: PostListProps) => {
	return (
		<ul className="lg:self-center lg:row-start-2">
			{posts.map((post) => (
				<li
					key={post.title}
					className={clsx(
						"first:border-t lg:first:border-t-0",
						"border-b last:border-b-0",
						"border-black",
						"py-6 xl:py-9 lg:first:pt-0 lg:last:pb-0",
						"group",
					)}
				>
					<PrismicLink href={post.href}>
						{post.category && (
							<Text asChild variant="heading5" uppercase>
								<h5>{post.category}</h5>
							</Text>
						)}

						{post.title && (
							<Text
								asChild
								variant="heading3"
								className="mt-4 transition group-hover:text-red"
							>
								<h3>{post.title}</h3>
							</Text>
						)}
					</PrismicLink>
				</li>
			))}
		</ul>
	)
}

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyBlogHighlights = ({
	heading,
	renderLeft,
	renderRight,
}: Props) => {
	return (
		<>
			<div className="w-full h-8 bg-white isolate" aria-hidden />
			<BoundedBox.Outer
				paddingY="blogHighlights"
				className="relative bg-gray-93"
			>
				<div className="absolute inset-0 mix-blend-overlay">
					<img
						src={blogHighlightUrl}
						alt=""
						loading="lazy"
						decoding="async"
						className="w-full h-full -scale-x-100 object-cover object-center"
					/>
				</div>

				<BoundedBox.Inner className="flex flex-col space-y-8 isolate">
					{heading && (
						<Text
							asChild
							variant="heading2"
							uppercase
							className="mx-auto text-center"
						>
							<h2>{heading}</h2>
						</Text>
					)}

					<div
						className={clsx(
							"grid",
							"lg:grid-cols-[1fr,1px,1fr]",
							"gap-y-10 lg:gap-y-0",
							"lg:gap-x-10 xl:gap-x-20",
						)}
					>
						{renderLeft}
						<div className="hidden w-full col-span-1 col-start-2 row-start-1 bg-transparent lg:block" />
						<div className="hidden w-full bg-black lg:block lg:col-start-2 lg:row-start-2" />
						{renderRight}

						<UnderlineLink
							href="/blog"
							uppercase={false}
							textVariant="heading3"
							variant="thick"
							className="mx-auto mt-10 md:mt-14 lg:col-span-full lg:mt-20"
						>
							Read More Posts
						</UnderlineLink>
					</div>
				</BoundedBox.Inner>
			</BoundedBox.Outer>
		</>
	)
}
PageDataBodyBlogHighlights.PostList = PostList
PageDataBodyBlogHighlights.Post = FeaturedPost

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyBlogHighlightsFragment>) {
	const primary = data.primary

	const largePostDoc = primary.large_featured_post?.document
	if (largePostDoc && largePostDoc.__typename !== "PrismicBlogPost") {
		throw new Error("Did not receive Blog Post when queried for!")
	}
	const largePostCategoryDoc = largePostDoc?.data.category?.document
	if (
		largePostCategoryDoc &&
		largePostCategoryDoc.__typename !== "PrismicBlogCategory"
	) {
		throw new Error("Did not receive Blog Category when queried for!")
	}

	const largePost = {
		href: largePostDoc?.url,
		title: largePostDoc?.data.title?.text,
		category: largePostCategoryDoc?.data.name?.text,
		imageAlt: largePostDoc?.data.image?.alt,
		image: largePostDoc?.data.image?.gatsbyImageData as IGatsbyImageData,
	}

	const posts = data.items.map((item) => {
		const postDoc = item.featured_post?.document
		if (postDoc && postDoc.__typename !== "PrismicBlogPost") {
			throw new Error("Did not receive Blog Post when queried for!")
		}
		const postCategoryDoc = postDoc?.data.category?.document
		if (
			postCategoryDoc &&
			postCategoryDoc.__typename !== "PrismicBlogCategory"
		) {
			throw new Error("Did not receive Blog Category when queried for!")
		}

		return {
			href: postDoc?.url,
			title: postDoc?.data.title?.text,
			category: postCategoryDoc?.data.name?.text,
		}
	})

	return {
		heading: primary.heading?.text,
		renderLeft: (<FeaturedPost {...largePost} />) as React.ReactElement | null,
		renderRight: (<PostList posts={posts} />) as React.ReactElement | null,
	}
}

export const fragment = graphql`
	fragment PageDataBodyBlogHighlights on PrismicPageDataBodyBlogHighlights {
		primary {
			heading {
				text
			}
			large_featured_post {
				document {
					__typename
					... on PrismicBlogPost {
						_previewable
						url
						data {
							image {
								alt
								gatsbyImageData(
									layout: FULL_WIDTH
									width: 800
									sizes: "(min-width: 90rem) 58rem, (min-width: 64rem) 55vw, 100vw"
								)
							}
							...SharedBlogData
						}
					}
				}
			}
		}
		items {
			featured_post {
				document {
					__typename
					... on PrismicBlogPost {
						_previewable
						url
						data {
							...SharedBlogData
						}
					}
				}
			}
		}
	}

	fragment SharedBlogData on PrismicBlogPostDataType {
		title {
			text
		}
		category {
			document {
				__typename
				... on PrismicBlogCategory {
					_previewable
					data {
						name {
							text
						}
					}
				}
			}
		}
	}
`

export default PageDataBodyBlogHighlights
