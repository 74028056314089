import clsx from "clsx"
import {
	type PrismicRichTextProps,
	PrismicRichText,
	PrismicLink,
} from "@prismicio/react"
import type { FilledLinkToDocumentField } from "@prismicio/types"

import { Text } from "./Text"
import { List } from "./List"
import { linkResolver } from "../prismic/linkResolver"

import * as cssStyles from "./RichText.module.css"

export const HEADING_CLASSES = clsx(
	"last:mb-0 mb-2 xl:mb-3",
	"mt-3 xl:mt-5 first:mt-0"
)

interface RichTextProps extends React.ComponentPropsWithoutRef<"div"> {
	field: NonNullable<PrismicRichTextProps["field"]>
	componentOverrides?: PrismicRichTextProps["components"]
}

export const RichText = ({
	field,
	className,
	componentOverrides,
	...props
}: RichTextProps) => {
	return (
		<div className={clsx("grid gap-6 lg:gap-7", className)} {...props}>
			<PrismicRichText
				field={field}
				components={{
					heading1: (props) => (
						<Text
							asChild
							variant="heading1"
							uppercase
							className={clsx(HEADING_CLASSES, "text-red")}
						>
							<h1>{props.children}</h1>
						</Text>
					),
					heading2: (props) => (
						<Text
							asChild
							variant="heading2"
							uppercase
							className={HEADING_CLASSES}
						>
							<h2>{props.children}</h2>
						</Text>
					),
					heading3: (props) => (
						<Text asChild variant="heading3" className={HEADING_CLASSES}>
							<h3>{props.children}</h3>
						</Text>
					),
					heading4: (props) => (
						<Text asChild variant="heading4" className="mt-3 first:mt-0">
							<h4>{props.children}</h4>
						</Text>
					),
					heading5: (props) => (
						<Text asChild variant="heading5" className="mt-3 first:mt-0">
							<h5>{props.children}</h5>
						</Text>
					),
					heading6: (props) => (
						<Text asChild variant="heading6" fontFamily="serif">
							<p>{props.children}</p>
						</Text>
					),
					paragraph: (props) => (
						<Text
							asChild
							variant="paragraph1"
							fontFamily="serif"
							className={cssStyles.paragraph}
							suppressHydrationWarning
						>
							<p>{props.children}</p>
						</Text>
					),
					strong: (props) => (
						<strong className="font-bold">{props.children}</strong>
					),
					list: (props) => <List className="my-2">{props.children}</List>,
					listItem: (props) => <List.Item>{props.children}</List.Item>,
					oList: (props) => (
						<List variant="ordered" className="my-2">
							{props.children}
						</List>
					),
					oListItem: (props) => (
						<List.Item variant="ordered" className="list-decimal">
							{props.children}
						</List.Item>
					),
					hyperlink: (props) => (
						<PrismicLink
							className="transition hover:text-red"
							href={
								props.node.data.url ??
								linkResolver(props.node.data as FilledLinkToDocumentField)
							}
						>
							{props.children}
						</PrismicLink>
					),
					...componentOverrides,
				}}
			/>
		</div>
	)
}
