/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	MapToComponents,
	type TCtxWithContext,
	type MapToComponentsProps,
} from "@walltowall/map-to-components"
import { sliceMap, mapDataToProps } from "../slices/PageDataBody"
import type { Location } from "../types"

interface Slice {
	__typename?: string
}

type Meta = {
	location: Location
}

export type MapDataToPropsCtx<
	TSlice extends Slice = any,
	TContext = any,
> = TCtxWithContext<string, typeof sliceMap, Slice, any, TSlice, Meta, TContext>

function formatTypename(typename?: string) {
	return typename?.replace("Prismic", "") ?? ""
}

function getType(slice: Slice) {
	return formatTypename(slice.__typename)
}

function getKey(slice: Slice, idx: number) {
	return formatTypename(slice.__typename) + idx.toString()
}

function defaultMapDataToProps({ data }: MapDataToPropsCtx) {
	return data
}

interface Props {
	slices: MapToComponentsProps["list"]
	location: Location
}

export const MapSlicesToComponents = ({ slices, location }: Props) => {
	const meta: MapToComponentsProps["meta"] = {
		location,
	}

	return (
		<MapToComponents
			map={sliceMap}
			mapDataToProps={mapDataToProps}
			getKey={getKey}
			getType={getType}
			list={slices}
			meta={meta}
			defaultMapDataToProps={defaultMapDataToProps}
			default={({ type }) => {
				if (process.env.NODE_ENV !== "development") return null

				return (
					<div className="px-8 py-20 font-bold text-center bg-twRed-200 text-twRed-900">
						Unable to find mapping for: {type}
					</div>
				)
			}}
		/>
	)
}
