import { useStaticQuery, graphql } from "gatsby"
import { SiteSettingsQuery } from "../graphql.gen"

export function useSiteSettings() {
	const result = useStaticQuery<SiteSettingsQuery>(graphql`
		query SiteSettings {
			prismicSettings {
				data {
					site_name {
						text
					}
					site_description {
						text
					}
					twitter_card {
						url(imgixParams: { width: 800, height: 418, q: 75 })
					}
					twitter_username
					open_graph_card {
						url(imgixParams: { width: 1200, height: 630, q: 75 })
					}
				}
			}
		}
	`)

	const settings = result.prismicSettings

	return {
		siteName: settings?.data.site_name?.text ?? "Very Law",
		siteDescription: settings?.data.site_description?.text,
		twitter: {
			username: settings?.data.twitter_username,
			cardImageUrl: settings?.data.twitter_card?.url,
		},
		openGraph: {
			cardImageUrl: settings?.data.open_graph_card?.url,
		},
	}
}
