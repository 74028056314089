import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import { PrismicLink } from "@prismicio/react"
import clsx from "clsx"

import { Text } from "../components/Text"
import { BoundedBox } from "../components/BoundedBox"

import type { PageDataBodyPeopleFragment } from "../graphql.gen"

const Person = ({ href, image, name, title }: Props["people"][number]) => {
	return (
		<PrismicLink href={href} className="group">
			{image && (
				<div className="aspect-w-1 aspect-h-1">
					<div className="bg-gray-93">
						<GatsbyImage
							image={image}
							alt={name ?? ""}
							objectFit="cover"
							objectPosition="center"
							className="w-full h-full transition duration-300 ease-in group-hover:brightness-105"
						/>
					</div>
				</div>
			)}

			<div className="mt-[18px] lg:mt-6 space-y-3.5 lg:space-y-5">
				{name && (
					<Text
						variant="heading3"
						asChild
						className="transition ease-in group-hover:text-red"
					>
						<h3>{name}</h3>
					</Text>
				)}
				{title && (
					<Text variant="heading4" asChild uppercase>
						<p>{title}</p>
					</Text>
				)}
			</div>
		</PrismicLink>
	)
}

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyPeople = ({ people }: Props) => {
	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner
				className={clsx(
					"grid",
					"gap-8 md:gap-y-12 xl:gap-x-12 2xl:gap-16",
					"md:grid-cols-2 lg:grid-cols-3",
				)}
			>
				{people.map((p) => (
					<Person key={p.name} {...p} />
				))}
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyPeopleFragment>) {
	const people = data.items
		.filter((item) => Boolean(item.person?.document))
		.map((item) => {
			const doc = item.person?.document

			if (doc?.__typename !== "PrismicPerson") {
				throw new Error(
					"Did not receive Person document when expected to receive one!",
				)
			}

			return {
				href: doc.url,
				name: doc.data.name?.text,
				image: doc.data.headshot?.gatsbyImageData as IGatsbyImageData,
				title: doc.data.title,
			}
		})

	return {
		people,
	}
}

export const fragment = graphql`
	fragment PageDataBodyPeople on PrismicPageDataBodyPeople {
		items {
			person {
				document {
					... on PrismicPerson {
						...PersonData
					}
				}
			}
		}
	}

	fragment PersonData on PrismicPerson {
		__typename
		_previewable
		url
		data {
			headshot {
				gatsbyImageData(
					width: 512
					layout: FULL_WIDTH
					sizes: "(min-width: 64rem) 28vw, (min-width: 48rem) 45vw, 90vw"
				)
			}
			name {
				text
			}
			title
		}
	}
`

export default PageDataBodyPeople
