import clsx from "clsx"
import { Link } from "gatsby"

interface ThickChevronButtonProps
	extends React.ComponentPropsWithoutRef<"button"> {
	label: string
	href?: string
	state?: Record<string, unknown>
}

export const ThickChevronButton = ({
	className,
	label,
	href,
	state,
	...props
}: ThickChevronButtonProps) => {
	const Component = href ? Link : "button"

	return (
		<Component
			className={clsx(
				"flex items-center justify-center",
				"w-10 h-10 -m-3",
				"text-red transition hover:text-black",
				className,
			)}
			// @ts-expect-error - To is only assigned when Link is the component we're
			// using. Lazy polymorphism :^)
			to={href ? href : undefined}
			state={href ? state : undefined}
			{...props}
		>
			<span className="sr-only">{label}</span>

			<svg viewBox="0 0 12 18" className="w-2.5 md:w-3">
				<use href="/images/icons.svg#vlw-thick-chevron" />
			</svg>
		</Component>
	)
}
