import clsx from "clsx"
import { Text } from "./Text"

const variants = {
	red: "text-red",
	black: "text-black",
}

interface BlockquoteProps extends React.ComponentPropsWithoutRef<"figure"> {
	children: string
	quotee?: string
	variant?: keyof typeof variants
}

export const Blockquote = ({
	children,
	quotee,
	variant = "red",
	...props
}: BlockquoteProps) => {
	const textColor = variants[variant]

	return (
		<figure {...props}>
			<div className="flex items-start space-x-1">
				<Text
					variant="blockquote"
					className={clsx("relative -top-2", textColor)}
				>
					“
				</Text>

				<div className="space-y-6 md:space-y-9 xl:space-y-11">
					<Text asChild variant="blockquote" className={textColor}>
						<blockquote>{children}”</blockquote>
					</Text>

					{quotee && (
						<div className="flex items-center space-x-1 lg:space-x-2.5">
							<div className="h-0.5 bg-black w-1.5 lg:w-4 lg:mb-0.5" />

							<Text asChild variant="blockquotee" uppercase>
								<figcaption>{quotee}</figcaption>
							</Text>
						</div>
					)}
				</div>
			</div>
		</figure>
	)
}
