/* eslint-disable @typescript-eslint/no-explicit-any */
import { graphql } from "gatsby"
import { type MapToComponentsProps } from "@walltowall/map-to-components"
import map from "just-map-values"

import type { MapDataToPropsCtx } from "../components/MapSlicesToComponents"

// 1. Import your Slice Module
import * as PageDataBodyAllBlogPosts from "./PageDataBodyAllBlogPosts"
import * as PageDataBodyAnimatedIntro from "./PageDataBodyAnimatedIntro"
import * as PageDataBodyBlogHighlights from "./PageDataBodyBlogHighlights"
import * as PageDataBodyCallToAction from "./PageDataBodyCallToAction"
import * as PageDataBodyCards from "./PageDataBodyCards"
import * as PageDataBodyFeaturedBlogPost from "./PageDataBodyFeaturedBlogPost"
import * as PageDataBodyForm from "./PageDataBodyForm"
import * as PageDataBodyHeadingWithAccordions from "./PageDataBodyHeadingWithAccordions"
import * as PageDataBodyHero from "./PageDataBodyHero"
import * as PageDataBodyHtmlEmbed from "./PageDataBodyHtmlEmbed"
import * as PageDataBodyImage from "./PageDataBodyImage"
import * as PageDataBodyPageIntro from "./PageDataBodyPageIntro"
import * as PageDataBodyPeople from "./PageDataBodyPeople"
import * as PageDataBodyPracticeAreas from "./PageDataBodyPracticeAreas"
import * as PageDataBodyQuoteWithImage from "./PageDataBodyQuoteWithImage"
import * as PageDataBodyRichText from "./PageDataBodyRichText"
import * as PageDataBodyRichtextcalltoaction from "./PageDataBodyRichtextcalltoaction"
import * as PageDataBodyTwoColumnText from "./PageDataBodyTwoColumnText"

interface SliceModule {
	default: (props: any) => JSX.Element | null
	mapDataToProps?: (ctx: MapDataToPropsCtx) => unknown
}

// 2. Add your slice to this SLICES array
const SLICES: Record<string, SliceModule> = {
	// Page Slices
	PageDataBodyHero,
	PageDataBodyCallToAction,
	PageDataBodyPracticeAreas,
	PageDataBodyRichText,
	PageDataBodyImage,
	PageDataBodyQuoteWithImage,
	PageDataBodyPeople,
	PageDataBodyPageIntro,
	PageDataBodyFeaturedBlogPost,
	PageDataBodyAllBlogPosts,
	PageDataBodyBlogHighlights,
	PageDataBodyForm,
	PageDataBodyAnimatedIntro,
	PageDataBodyHtmlEmbed,
	PageDataBodyCards,
	PageDataBodyHeadingWithAccordions,
	PageDataBodyTwoColumnText,
	PageDataBodyRichtextcalltoaction,

	// Blog Post Slices
	BlogPostDataBodyRichText: PageDataBodyRichText,
	BlogPostDataBodyImage: PageDataBodyImage,
	BlogPostDataBodyQuoteWithImage: PageDataBodyQuoteWithImage,
}
export const sliceMap = map(
	SLICES,
	(module) => module.default
) as MapToComponentsProps["map"]
export const mapDataToProps = map(
	SLICES,
	(module) => module.mapDataToProps
) as MapToComponentsProps["mapDataToProps"]

// 3. Spread your Slice GraphQL fragment below
export const fragment = graphql`
	fragment PageDataBody on PrismicPageDataBodySlicesType {
		...PageDataBodyHero
		...PageDataBodyCallToAction
		...PageDataBodyPracticeAreas
		...PageDataBodyRichText
		...PageDataBodyImage
		...PageDataBodyQuoteWithImage
		...PageDataBodyPeople
		...PageDataBodyPageIntro
		...PageDataBodyFeaturedBlogPost
		...PageDataBodyBlogHighlights
		...PageDataBodyForm
		...PageDataBodyAnimatedIntro
		...PageDataBodyHtmlEmbed
		...PageDataBodyCards
		...PageDataBodyHeadingWithAccordions
		...PageDataBodyTwoColumnText
		...PageDataBodyRichtextcalltoaction

		# The following Slices don't have fragments, and are only used
		# programatically.
		# ...PageDataBodyAllBlogPosts
	}

	fragment BlogPostDataBody on PrismicBlogPostDataBodySlicesType {
		...BlogPostDataBodyImage
		...BlogPostDataBodyQuoteWithImage
		...BlogPostDataBodyRichText
	}
`
