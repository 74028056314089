import { graphql } from "gatsby"
import { type IGatsbyImageData } from "gatsby-plugin-image"
import { BoundedBox } from "../components/BoundedBox"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import type { PageDataBodyCardsFragment } from "../graphql.gen"
import { CardGrid } from "../components/CardGrid"
import { Card } from "../components/Card"
import { DescriptionCardProps } from "../components/DescriptionCard"
import blogDefaultUrl from "../assets/blog-default.jpg"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyCards = ({ color = "black", cards = [] }: Props) => {
	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner>
				<CardGrid>
					{cards.map((card, idx) => (
						<CardGrid.Item key={card.heading ?? idx}>
							<Card
								subheading={card.subheading}
								heading={card.heading}
								richText={card.richText}
								href={card.href}
								image={card.image}
								imageAlt={card.imageAlt}
								linkText={card.linkText}
								color={color}
								className="h-full"
								renderImage={
									!card.image ? (
										<img
											src={blogDefaultUrl}
											loading="lazy"
											decoding="async"
											className="object-cover object-center w-full h-full"
											alt=""
											width={1600}
											height={1073}
										/>
									) : undefined
								}
							/>
						</CardGrid.Item>
					))}
				</CardGrid>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyCardsFragment>) {
	const color: NonNullable<DescriptionCardProps["color"]> =
		(data.primary.color?.toLowerCase() as DescriptionCardProps["color"]) ??
		"black"

	return {
		color,
		cards: data.items.map((item) => ({
			heading: item.heading?.text,
			subheading: item.subheading?.text,
			richText: item.text?.richText,
			linkText: item.link_text,
			href: item.link?.url,
			image: item.image?.gatsbyImageData as IGatsbyImageData,
			imageAlt: item.image?.alt,
		})),
	}
}

export const fragment = graphql`
	fragment PageDataBodyCards on PrismicPageDataBodyCards {
		primary {
			color
		}
		items {
			heading {
				text
			}
			subheading {
				text
			}
			text {
				richText
			}
			link_text
			link {
				url
			}
			image {
				alt
				gatsbyImageData(width: 400, sizes: "25rem", layout: FULL_WIDTH)
			}
		}
	}
`

export default PageDataBodyCards
