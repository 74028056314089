import clsx from "clsx"
import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import { BoundedBox } from "../components/BoundedBox"
import { Blockquote } from "../components/Blockquote"

import type { PageDataBodyQuoteWithImageFragment } from "../graphql.gen"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyQuoteWithImage = ({
	quote,
	quotee,
	image,
	imageAlt,
}: Props) => {
	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner
				className={clsx(
					"grid",
					"lg:grid-cols-[2fr,3fr]",
					"lg:items-center",
					"gap-y-7",
					"lg:gap-x-8 xl:gap-x-12 2xl:gap-x-20",
				)}
			>
				{quote && (
					<Blockquote
						className={clsx(
							"max-w-[240px] sm:max-w-lg 2xl:max-w-xl 3xl:max-w-2xl",
							"mx-auto lg:mr-0 lg:-ml-10 2xl:-ml-14 3xl:-ml-28",
						)}
						quotee={quotee}
					>
						{quote}
					</Blockquote>
				)}

				<div
					className={clsx(
						"-mr-[26px] lg:-mr-[50px] 2xl:-mr-[69px]",
						"aspect-w-[295] aspect-h-[281] md:aspect-w-[785] md:aspect-h-[525]",
					)}
				>
					<div className="bg-gray-93">
						<GatsbyImage
							image={image}
							alt={imageAlt ?? ""}
							className="w-full h-full"
							objectFit="cover"
							objectPosition="center"
						/>
					</div>
				</div>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyQuoteWithImageFragment>) {
	const primary = data.primary

	return {
		quote: primary.quote?.text,
		quotee: primary.quotee,
		image: primary.image?.gatsbyImageData as IGatsbyImageData,
		imageAlt: primary.image?.alt,
	}
}

export const fragment = graphql`
	fragment PageDataBodyQuoteWithImage on PrismicPageDataBodyQuoteWithImage {
		primary {
			quote {
				text
			}
			quotee
			image {
				gatsbyImageData(
					width: 981
					sizes: "(min-width: 90rem) 100rem, (min-width: 64rem) 60vw, 90vw"
					layout: FULL_WIDTH
				)
				alt
			}
		}
	}

	fragment BlogPostDataBodyQuoteWithImage on PrismicBlogPostDataBodyQuoteWithImage {
		primary {
			quote {
				text
			}
			quotee
			image {
				gatsbyImageData(
					width: 981
					sizes: "(min-width: 90rem) 100rem, (min-width: 64rem) 60vw, 90vw"
					layout: FULL_WIDTH
				)
				alt
			}
		}
	}
`

export default PageDataBodyQuoteWithImage
