import { Link, GatsbyLinkProps } from "gatsby"
import veryLawSvg from "../assets/very-law.svg"
import veryLawDarkSvg from "../assets/very-law-dark.svg"
import clsx from "clsx"

const variants = {
	light: veryLawSvg,
	dark: veryLawDarkSvg,
}

interface Props extends Omit<GatsbyLinkProps<never>, "to" | "ref"> {
	variant?: keyof typeof variants
}

export const VeryLawLogo = ({
	variant = "light",
	className,
	...props
}: Props) => {
	const svgUrl = variants[variant]

	return (
		<Link to="/" className={clsx("block", className)} {...props}>
			<span className="sr-only">Very Law</span>

			<img
				className="w-full"
				src={svgUrl}
				alt="Very Law"
				loading="eager"
				decoding="async"
			/>
		</Link>
	)
}
