/**
 * This file contains a template for all Page documents in your Prismic
 * repository. It uses Gatsby's File System Route API to automatically generate
 * a page for each document using its `url` field. The `url` field is computed
 * using your app's Link Resolver.
 *
 * This template supports Prismic previews using the `withPrismicPreview` higher
 * order component.
 *
 * @see https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api/
 */

import { graphql, type HeadProps, type PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { MapSlicesToComponents } from "../components/MapSlicesToComponents"
import { SEO } from "../components/SEO"
import { useSiteSettings } from "../hooks/useSiteSettings"

import type { PageTemplateQuery } from "../graphql.gen"
import { jsonParseSafe } from "../lib/jsonParseSafe"

const PageTemplate = ({ data, location }: PageProps<PageTemplateQuery>) => {
	const page = data.prismicPage

	return (
		<>
			{location.pathname === "/" && (
				<h1 className="sr-only">Pittsburgh Law Firm</h1>
			)}
			<MapSlicesToComponents slices={page?.data.body} location={location} />{" "}
		</>
	)
}

export const Head = ({ data, location }: HeadProps<PageTemplateQuery>) => {
	const settings = useSiteSettings()
	const page = data.prismicPage
	const structuredData = jsonParseSafe(page?.data.structured_data?.text)

	return (
		<>
			<SEO
				siteName={settings.siteName}
				siteDescription={settings.siteDescription}
				pathname={location.pathname}
				pageTitle={page?.uid === "home" ? undefined : page?.data.title?.text}
				structuredData={structuredData}
				meta={{
					description: page?.data.meta_description?.text,
					title: page?.data.meta_title?.text,
				}}
				twitter={{
					username: settings.twitter.username,
					cardImageUrl:
						page?.data.twitter_card?.url ?? settings.twitter.cardImageUrl,
				}}
				openGraph={{
					cardImageUrl:
						page?.data.open_graph_image?.url ?? settings.openGraph.cardImageUrl,
				}}
			/>
			<meta
				name="google-site-verification"
				content="bAPnQ0NBmOHtWpjpjsIFxQ-atDrOq--aEWX-PPnibDo"
			/>
		</>
	)
}

/**
 * When a Prismic preview session is active, `withPrismicPreview` will
 * automatically provide your template with updated preview content. As editors
 * edit and save content in the Prismic writing room, the page will
 * automatically refresh to display the edited content.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicpreview
 */
export default withPrismicPreview(PageTemplate)

export const query = graphql`
	query PageTemplate($id: String!) {
		prismicPage(id: { eq: $id }) {
			...PageData
		}
	}

	fragment PageData on PrismicPage {
		# The _previewable field enables previews for this document.
		_previewable
		uid
		data {
			title {
				text
			}
			meta_description {
				text
			}
			meta_title {
				text
			}
			structured_data {
				text
			}
			open_graph_image {
				url(imgixParams: { width: 1200, height: 630, q: 75 })
			}
			twitter_card {
				url(imgixParams: { width: 800, height: 418, q: 75 })
			}

			body {
				__typename
				...PageDataBody
			}
		}
	}
`
