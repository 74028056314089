import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import { isFilled } from "@prismicio/helpers"
import { PrismicRichText } from "@prismicio/react"
import clsx from "clsx"

import { Text } from "../components/Text"
import { BoundedBox } from "../components/BoundedBox"

import type { PageDataBodyImageFragment } from "../graphql.gen"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyImage = ({
	caption,
	image,
	imageAlt,
	location,
}: Props) => {
	if (!image) return null

	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner asChild>
				<figure className="space-y-4">
					<div
						className={clsx(
							"max-w-[51.25rem]",
							location?.pathname.includes("/blog/")
								? "mx-0"
								: "-mx-[26px] md:mr-0 lg:-ml-[50px] 2xl:-ml-[69px]",
						)}
					>
						<GatsbyImage
							image={image}
							alt={imageAlt ?? ""}
							className="bg-gray-93"
						/>
					</div>

					{isFilled.richText(caption) && (
						<figcaption>
							<PrismicRichText
								field={caption}
								components={{
									paragraph: (props) => (
										<Text variant="paragraph2">{props.children}</Text>
									),
								}}
							/>
						</figcaption>
					)}
				</figure>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
	meta,
}: MapDataToPropsCtx<PageDataBodyImageFragment>) {
	const primary = data.primary

	return {
		image: primary.image?.gatsbyImageData as IGatsbyImageData,
		imageAlt: primary.image?.alt,
		caption: primary.caption?.richText,
		location: meta?.location,
	}
}

export const fragment = graphql`
	fragment PageDataBodyImage on PrismicPageDataBodyImage {
		primary {
			caption {
				richText
			}
			image {
				gatsbyImageData(width: 850)
				alt
			}
		}
		__typename
	}

	fragment BlogPostDataBodyImage on PrismicBlogPostDataBodyImage {
		primary {
			caption {
				richText
			}
			image {
				gatsbyImageData(width: 850)
				alt
			}
		}
		__typename
	}
`

export default PageDataBodyImage
