/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react"
import clsx from "clsx"
import { animate } from "motion"

const Line = React.forwardRef<HTMLDivElement, unknown>((_props, ref) => {
	return (
		<div
			ref={ref}
			className="h-[3px] bg-black w-[26px] group-hover:bg-red transition-colors"
		/>
	)
})
Line.displayName = "Line"

interface MenuButtonProps extends React.ComponentPropsWithoutRef<"button"> {
	isOpen: boolean
}

export const MenuButton = React.forwardRef<HTMLButtonElement, MenuButtonProps>(
	({ isOpen, className, ...props }, ref) => {
		const firstLineRef = React.useRef<HTMLDivElement>(null!)
		const secondLineRef = React.useRef<HTMLDivElement>(null!)
		const thirdLineRef = React.useRef<HTMLDivElement>(null!)

		React.useEffect(() => {
			async function asyncEffect() {
				const firstLine = firstLineRef.current
				const secondLine = secondLineRef.current
				const thirdLine = thirdLineRef.current

				const yUnits = 9

				if (isOpen) {
					await Promise.all([
						animate(secondLine, { opacity: 0 }).finished,
						animate(firstLine, { y: yUnits }).finished,
						animate(thirdLine, { y: -yUnits }).finished,
					])

					animate(firstLine, { rotate: 45 })
					animate(thirdLine, { rotate: -45 })
				} else {
					await Promise.all([
						animate(firstLine, { rotate: 0 }).finished,
						animate(thirdLine, { rotate: 0 }).finished,
					])

					animate(secondLine, { opacity: 1 })
					animate(firstLine, { y: 0 })
					animate(thirdLine, { y: 0 })
				}
			}

			asyncEffect()
		}, [isOpen])

		return (
			<button
				ref={ref}
				className={clsx(
					className,
					"grid w-10 h-10 place-items-center place-content-center gap-1.5",
					"mx-[-7px] my-[-12.5px]",
					"group",
				)}
				{...props}
			>
				<Line ref={firstLineRef} />
				<Line ref={secondLineRef} />
				<Line ref={thirdLineRef} />
			</button>
		)
	},
)
MenuButton.displayName = "MenuButton"
