import { isFilled } from "@prismicio/helpers"
import { BoundedBox } from "../components/BoundedBox"
import { Text } from "../components/Text"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { graphql } from "gatsby"
import { PrismicPageDataBodyRichtextcalltoaction } from "../graphql.gen"
import { PrismicLink, PrismicRichText } from "@prismicio/react"
import { Button } from "../components/Button"
import clsx from "clsx"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyRichtextcalltoaction = ({
	heading,
	introCopy,
	ctaCopy,
	buttonHref,
	buttonText,
}: Props) => {
	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner className="grid md:grid-cols-2 lg:grid-cols-[auto_430px] 2.5xl:grid-cols-2 gap-16 md:gap-6">
				<div className="max-w-[53.125rem]">
					{heading && (
						<Text
							variant="richTextCTAHeading"
							asChild
							uppercase
							className="max-w-[27.5rem]"
						>
							<h2>{heading}</h2>
						</Text>
					)}
					<div className="mt-10 flex flex-col gap-4">
						{isFilled.richText(ctaCopy) && (
							<PrismicRichText
								field={ctaCopy}
								components={{
									paragraph: (props) => (
										<Text asChild variant="paragraph1" fontFamily="serif">
											<p>{props.children}</p>
										</Text>
									),
								}}
							/>
						)}
					</div>

					{buttonText && buttonHref && (
						<Button color="black" asChild className="mt-10">
							<PrismicLink href={buttonHref}>{buttonText}</PrismicLink>
						</Button>
					)}
				</div>

				<div className="relative self-center 2xl:min-w-[430px] 2xl:mx-auto">
					<svg viewBox="0 0 188 163" fill="none" className="-z-10 w-[187px]">
						<path d="M121.5 0H0L65.8671 163H187.367L121.5 0Z" fill="#F5F5F5" />
					</svg>

					{isFilled.richText(introCopy) && (
						<div className="max-w-[443px] absolute top-1/2 -translate-y-1/2 left-20">
							<PrismicRichText
								field={introCopy}
								components={{
									paragraph: (props) => (
										<Text
											asChild
											variant="paragraph1"
											className="[&>strong]:text-red"
										>
											<p>{props.children}</p>
										</Text>
									),
								}}
							/>
						</div>
					)}
				</div>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PrismicPageDataBodyRichtextcalltoaction>) {
	const primary = data.primary

	return {
		heading: primary.heading?.text,
		introCopy: primary.intro_copy?.richText,
		ctaCopy: primary.call_to_action_copy?.richText,
		buttonHref: primary.button_link?.url,
		buttonText: primary.link_text,
	}
}

export const fragment = graphql`
	fragment PageDataBodyRichtextcalltoaction on PrismicPageDataBodyRichtextcalltoaction {
		primary {
			heading {
				text
			}
			intro_copy {
				richText
			}
			call_to_action_copy {
				richText
			}
			link_text
			button_link {
				url
			}
		}
	}
`

export default PageDataBodyRichtextcalltoaction
