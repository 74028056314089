import clsx from "clsx"
import { SearchIcon } from "./SearchIcon"

interface SearchButtonProps extends React.ComponentPropsWithoutRef<"button"> {
	widthClassName?: string
	negativeMarginClassName?: string
}

export const SearchButton = ({
	className,
	widthClassName = "w-[18px]",
	negativeMarginClassName = "m-[calc((40px-18px)/-2)]",
	...props
}: SearchButtonProps) => {
	return (
		<button
			className={clsx(
				"flex items-center justify-center",
				"w-10 h-10",
				"hover:text-red transition",
				negativeMarginClassName,
				className,
			)}
			{...props}
		>
			<span className="sr-only">Search</span>
			<SearchIcon className={clsx(widthClassName, "fill-white")} />
		</button>
	)
}
