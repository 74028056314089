export const MinusIcon = ({
	className,
}: React.ComponentPropsWithoutRef<"svg">) => {
	return (
		<svg fill="none" viewBox="0 0 20 3" className={className}>
			<path
				stroke="currentColor"
				strokeLinecap="square"
				strokeLinejoin="round"
				strokeWidth="3"
				d="M18.438 1.5H1.563"
			/>
		</svg>
	)
}
