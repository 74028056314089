import * as React from "react"
import clsx from "clsx"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import * as styles from "./HoverZoomImage.module.css"

export interface HoverZoomImageProps
	extends React.ComponentPropsWithoutRef<"div"> {
	image?: IGatsbyImageData
	alt?: string
	renderImage?: React.ReactElement
	disable?: boolean
}

export const HoverZoomImage = ({
	image,
	alt,
	className,
	renderImage,
	disable = false,
	...props
}: HoverZoomImageProps) => {
	const imageProps = {
		className: "w-full h-full",
		alt: alt ?? "",
	}

	return (
		<div
			className={clsx(
				!disable && styles.hoverZoomImage,
				"overflow-hidden h-full",
				className,
			)}
			data-disable={disable}
			{...props}
		>
			{renderImage && renderImage}
			{!renderImage && image && (
				<GatsbyImage
					image={image}
					objectFit="cover"
					objectPosition="center"
					{...imageProps}
				/>
			)}
		</div>
	)
}
