import * as React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import { PrismicProvider } from "@prismicio/react"
import { repositoryConfigs } from "../prismic/previews"
import { Link } from "gatsby"

interface LinkProps extends React.ComponentPropsWithoutRef<"a"> {
	href: string
}

const InternalLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
	({ href, ...props }, ref) => {
		//@ts-expect-error - Error for Gatsby types, ignore for now.
		return <Link ref={ref} to={href} {...props} />
	}
)
InternalLink.displayName = "InternalLink"

const ExternalLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
	({ href, ...props }, ref) => {
		const isInternal =
			href.includes("verylaw.com") || href.includes("localhost")

		return (
			<a
				ref={ref}
				href={href}
				target={isInternal ? undefined : "_blank"}
				rel={isInternal ? undefined : "noopener noreferrer"}
				{...props}
			/>
		)
	}
)
ExternalLink.displayName = "ExternalLink"

interface ProvidersProps {
	children: React.ReactNode
}

export const Providers = ({ children }: ProvidersProps) => {
	return (
		<PrismicProvider
			internalLinkComponent={InternalLink}
			externalLinkComponent={ExternalLink}
		>
			<PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
				{children}
			</PrismicPreviewProvider>
		</PrismicProvider>
	)
}
