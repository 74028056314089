import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import type { PageDataBodyPageIntroFragment } from "../graphql.gen"
import { Text } from "../components/Text"
import { BoundedBox } from "../components/BoundedBox"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyPageIntro = ({ heading }: Props) => {
	return (
		<BoundedBox
			paddingY="pageIntro"
			className="relative overflow-hidden bg-black"
		>
			<Text variant="pageIntro" asChild>
				<h1 className="text-white max-w-[73%] 2xl:max-w-[68%]">{heading}</h1>
			</Text>

			<div className="bg-red left-[75%] absolute h-full -right-[50vw] top-0 skew-x-[23deg] 2xl:left-[70%]" />
		</BoundedBox>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyPageIntroFragment>) {
	return {
		heading: data.primary.heading?.text,
	}
}

export const fragment = graphql`
	fragment PageDataBodyPageIntro on PrismicPageDataBodyPageIntro {
		__typename
		primary {
			heading {
				text
			}
		}
	}
`

export default PageDataBodyPageIntro
