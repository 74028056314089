import * as React from "react"
import clsx from "clsx"
import { SearchButton } from "./SearchButton"

const variants = {
	focusBorder: {
		formClasses: "h-11 focus-within:border-red border-transparent",
		inputClasses: "text-20",
		xClasses: "w-[17px]",
		searchButtonWidthClassName: undefined,
		searchButtonNegativeMarginClassName: undefined,
	},
	border: {
		formClasses: "h-11 border-black focus-within:border-red",
		inputClasses: "text-20",
		xClasses: "w-[17px]",
		searchButtonWidthClassName: undefined,
		searchButtonNegativeMarginClassName: undefined,
	},
	smallInput: {
		formClasses: "h-9 border-black focus-within:border-red",
		inputClasses: "text-16",
		xClasses: "w-[15px]",
		searchButtonWidthClassName: "w-[15px]",
		searchButtonNegativeMarginClassName: "m-[calc((40px-15px)/-2)]",
	},
}

interface SearchInputProps extends React.ComponentPropsWithoutRef<"form"> {
	variant: keyof typeof variants
	placeholder?: string
	defaultValue?: string
	onSearchIconClick?: () => void
}

const XButton = (props: React.ComponentPropsWithoutRef<"button">) => {
	return (
		<button {...props}>
			<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
				<path
					d="M2 2.36 19 19.5M2 19.36 19 2.22"
					stroke="currentColor"
					strokeWidth={2}
					strokeLinecap="square"
				/>
			</svg>
		</button>
	)
}

export const SearchInput = ({
	className,
	variant,
	placeholder,
	defaultValue,
	name = "query",
	onSearchIconClick,
	...props
}: SearchInputProps) => {
	const styles = variants[variant]
	const formRef = React.useRef<HTMLFormElement>(null)

	function handleClearClick() {
		if (!formRef.current) return

		const input = formRef.current.elements.namedItem(
			name,
		) as HTMLInputElement | null
		if (!input) return

		input.value = ""
	}

	return (
		<form
			ref={formRef}
			className={clsx(
				"relative flex items-center",
				"border-[3px]",
				"transition",
				"px-3 w-full",
				"group",
				styles.formClasses,
				className,
			)}
			{...props}
		>
			<SearchButton
				type={onSearchIconClick ? "button" : "submit"}
				className="shrink-0"
				onClick={onSearchIconClick}
				widthClassName={styles.searchButtonWidthClassName}
				negativeMarginClassName={styles.searchButtonNegativeMarginClassName}
			/>

			<input
				type="search"
				name={name}
				className={clsx(
					"w-full border-none outline-none h-full focus:outline-none focus:ring-0",
					"placeholder:text-black/60",
					"font-semibold",
					"appearance-none",
					styles.inputClasses,
				)}
				placeholder={placeholder}
				defaultValue={defaultValue}
			/>

			<XButton
				className={clsx(
					"ml-auto shrink-0 transition-all hover:text-red",
					"opacity-0 group-focus-within:opacity-100",
					"invisible group-focus-within:visible",
					styles.xClasses,
				)}
				type="button"
				onClick={handleClearClick}
			/>
		</form>
	)
}
