import { useStaticQuery, graphql } from "gatsby"
import type { PersonSettingsQuery } from "../graphql.gen"
import type { ColorTheme } from "../slices/PageDataBodyCallToAction"

export function usePersonSettings() {
	const result = useStaticQuery<PersonSettingsQuery>(graphql`
		query PersonSettings {
			prismicSettings {
				data {
					person_cta_heading {
						text
					}
					person_cta_text {
						richText
					}
					person_cta_link {
						url
					}
					person_cta_link_text
					person_cta_color_theme
				}
			}
		}
	`)

	const data = result.prismicSettings?.data

	return {
		ctaHeading: data?.person_cta_heading?.text,
		ctaText: data?.person_cta_text?.richText,
		ctaHref: data?.person_cta_link?.url,
		ctaLinkText: data?.person_cta_link_text,
		ctaColorTheme: (data?.person_cta_color_theme?.toLowerCase() ??
			"black") as ColorTheme,
	}
}
