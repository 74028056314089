import type { LegalService, WithContext } from "schema-dts"

export interface SEOProps {
	siteName: string
	siteDescription?: string
	pageTitle?: string
	structuredData?: Record<string, unknown>

	meta?: {
		title?: string
		description?: string
	}

	openGraph?: {
		cardImageUrl?: string
	}

	twitter?: {
		username?: string
		cardImageUrl?: string
	}
	pathname?: string
}

export const SEO = ({
	siteName,
	siteDescription,
	pageTitle,
	meta,
	openGraph,
	twitter,
	pathname,
	structuredData,
}: SEOProps) => {
	const resolvedDescription = meta?.description || siteDescription
	const resolvedPageTitle = meta?.title || pageTitle

	const resolvedTitle = resolvedPageTitle
		? resolvedPageTitle.length > 45
			? resolvedPageTitle
			: `${resolvedPageTitle} | ${siteName}`
		: siteName

	const legalServiceSchema: WithContext<LegalService> = {
		"@context": "https://schema.org",
		"@type": "LegalService",
		name: "Very Law",
		description: siteDescription,
		currenciesAccepted: "USD",
		openingHours: "Mo-Fr 09:00-17:00",
		paymentAccepted:
			"Cash, Credit Card, Debit Card, PayPal, Check, Venmo, eCheck / ACH, Wire",
		address: {
			"@type": "PostalAddress",
			addressLocality: "Pittsburgh",
			addressRegion: "PA",
			postalCode: "15219",
			streetAddress: "500 Grant St Suite 2900",
		},
		url: "https://www.verylaw.com",
		logo: "https://www.verylaw.com/images/logo.jpg",
		sameAs: [
			"https://www.facebook.com/verylaw/",
			"https://www.yelp.com/biz/very-law-pittsburgh-9",
			"https://www.bbb.org/us/pa/pittsburgh/profile/lawyers/very-law-pllc-0141-71027241",
			"https://www.instagram.com/verylaw/",
		],
		geo: {
			"@type": "GeoCoordinates",
			latitude: "40.4396783",
			longitude: "-79.9959316",
		},
		telephone: "+14124300131",
		email: "info@verylaw.com",
	}

	return (
		<>
			<title>{resolvedTitle}</title>
			{resolvedDescription && (
				<meta name="description" content={resolvedDescription} />
			)}
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(legalServiceSchema) }}
			/>
			{structuredData && (
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
				/>
			)}
			{pathname && (
				<link rel="canonical" href={"https://www.verylaw.com" + pathname} />
			)}

			{/* Open Graph */}
			<meta property="og:title" content={resolvedTitle} />
			{resolvedDescription && (
				<meta property="og:description" content={resolvedDescription} />
			)}
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content={siteName} />
			{openGraph?.cardImageUrl && (
				<meta
					name="image"
					property="og:image"
					content={openGraph.cardImageUrl}
				/>
			)}

			{/* Twitter */}
			<meta name="twitter:title" content={resolvedTitle} />
			{resolvedDescription && (
				<meta name="twitter:description" content={resolvedDescription} />
			)}
			<meta name="twitter:card" content="summary_large_image" />
			{twitter?.cardImageUrl && (
				<meta name="twitter:image" content={twitter.cardImageUrl} />
			)}
			{twitter?.username && (
				<meta name="twitter:site" content={twitter.username} />
			)}

			<link
				rel="prefetch"
				as="image"
				type="image/svg+xml"
				href="/images/icons.svg"
			/>
		</>
	)
}
