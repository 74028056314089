import * as React from "react"
import { PrismicLink } from "@prismicio/react"
import clsx from "clsx"
import { Text, type TextProps } from "./Text"

const variants = {
	default: {
		paddingBottom: "pb-2 lg:pb-2.5",
		border: "h-0.5",
		scaleHover: "group-hover:scale-y-[2]",
	},
	thick: {
		paddingBottom: "pb-2 lg:pb-3",
		border: "h-1",
		scaleHover: "group-hover:scale-y-[1.5]",
	},
}

interface UnderlineLinkProps extends React.ComponentPropsWithoutRef<"a"> {
	uppercase?: TextProps["uppercase"]
	textVariant?: TextProps["variant"]
	variant?: keyof typeof variants
}

export const UnderlineLink = React.forwardRef<
	HTMLAnchorElement,
	UnderlineLinkProps
>(
	(
		{
			href,
			className,
			children,
			uppercase = true,
			textVariant = "heading4",
			variant = "default",
			...props
		},
		ref,
	) => {
		const styles = variants[variant]

		return (
			<PrismicLink
				ref={ref}
				href={href}
				className={clsx(
					"block relative group",
					styles.paddingBottom,
					className,
				)}
				{...props}
			>
				<Text variant={textVariant} uppercase={uppercase}>
					{children}
				</Text>

				<div
					className={clsx(
						styles.border,
						styles.scaleHover,
						"absolute inset-x-0 bg-red bottom-0 origin-top",
						"transition duration-300",
					)}
				/>
			</PrismicLink>
		)
	},
)
UnderlineLink.displayName = "UnderlineLink"
