import { useStaticQuery, graphql } from "gatsby"
import type { PracticeAreaSettingsQuery } from "../graphql.gen"
import type { ColorTheme } from "../slices/PageDataBodyCallToAction"

export function usePracticeAreaSettings() {
	const result = useStaticQuery<PracticeAreaSettingsQuery>(graphql`
		query PracticeAreaSettings {
			prismicSettings {
				data {
					practice_areas_cta_heading {
						text
					}
					practice_areas_cta_text {
						richText
					}
					practice_areas_cta_link {
						url
					}
					practice_areas_cta_link_text
					practice_areas_cta_color_theme
				}
			}
		}
	`)

	const data = result.prismicSettings?.data

	return {
		ctaHeading: data?.practice_areas_cta_heading?.text,
		ctaText: data?.practice_areas_cta_text?.richText,
		ctaHref: data?.practice_areas_cta_link?.url,
		ctaLinkText: data?.practice_areas_cta_link_text,
		ctaColorTheme: (data?.practice_areas_cta_color_theme?.toLowerCase() ??
			"black") as ColorTheme,
	}
}
