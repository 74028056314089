export const PlusIcon = (props: React.ComponentPropsWithoutRef<"svg">) => {
	return (
		<svg fill="none" viewBox="0 0 20 21" {...props}>
			<path
				stroke="currentColor"
				strokeLinecap="square"
				strokeLinejoin="round"
				strokeWidth="3"
				d="M10 2.063v16.875m8.438-8.438H1.563"
			/>
		</svg>
	)
}
