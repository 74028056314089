import * as React from "react"
import { isFilled } from "@prismicio/helpers"
import { PrismicLink, PrismicRichText } from "@prismicio/react"
import type { RichTextField } from "@prismicio/types"
import clsx from "clsx"
import { type GetVariants, twix } from "@asyarb/twix"
import { UnderlineLink } from "./UnderlineLink"
import { Text } from "./Text"

import * as cssStyles from "./DescriptionCard.module.css"
import { ConditionalWrap } from "./ConditionalWrap"

const descriptionCard = twix({
	base: clsx("flex flex-col", cssStyles.blackCard),
	variants: {
		size: {
			large: clsx("px-6 md:px-9 xl:px-[55px]", "py-7 md:py-10 xl:py-[55px]"),
			small: clsx("px-6 md:px-9", "py-7 md:py-10 xl:py-[50px]"),
		},
		color: {
			black: "text-white bg-black",
			gray: "text-black bg-gray-93",
		},
	},
	defaultVariants: {
		size: "large",
		color: "black",
	},
})

export type DescriptionCardProps = React.ComponentPropsWithoutRef<"div"> & {
	subheading?: string
	heading?: string
	richText?: RichTextField
	href?: string
	linkText?: string
} & GetVariants<typeof descriptionCard>

export const DescriptionCard = ({
	subheading,
	heading,
	richText,
	href,
	className,
	size,
	color,
	children,
	linkText,
	...props
}: DescriptionCardProps) => {
	return (
		<div
			className={descriptionCard({ color, size, class: className })}
			{...props}
		>
			{children && children}

			{!children && (
				<>
					<div className="space-y-4 lg:space-y-6">
						{subheading && (
							<Text variant="heading5" uppercase>
								{subheading}
							</Text>
						)}

						{heading && (
							<>
								<Text variant="heading3" className={clsx(cssStyles.heading)}>
									<ConditionalWrap
										condition={Boolean(href)}
										wrap={(children) => (
											<PrismicLink
												className="descriptionCard__anchor transition hover:text-red"
												href={href}
											>
												{children}
											</PrismicLink>
										)}
									>
										{heading}
									</ConditionalWrap>
								</Text>

								<div
									aria-hidden
									className="h-[5px] bg-red w-8 mt-4 lg:h-1.5 lg:w-10 lg:mt-6"
								/>
							</>
						)}
					</div>

					{isFilled.richText(richText) && (
						<div
							className={clsx(
								"mt-6 md:mt-8 lg:mt-10",
								href && "mb-6 md:mb-8 lg:mb-10",
								"space-y-5",
							)}
						>
							<PrismicRichText
								field={richText}
								components={{
									paragraph: (props) => (
										<Text asChild variant="paragraph1" fontFamily="serif">
											<p>{props.children}</p>
										</Text>
									),
								}}
							/>
						</div>
					)}

					{href && linkText && (
						<UnderlineLink href={href} className="mt-auto mr-auto">
							{linkText}
						</UnderlineLink>
					)}
				</>
			)}
		</div>
	)
}
