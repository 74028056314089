/**
 * This file contains a template for all Person documents in your Prismic
 * repository. It uses Gatsby's File System Route API to automatically generate
 * a page for each document using its `url` field. The `url` field is computed
 * using your app's Link Resolver.
 *
 * This template supports Prismic previews using the `withPrismicPreview` higher
 * order component.
 *
 * @see https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api/
 */

import { graphql, type PageProps, type HeadProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import type { RichTextField } from "@prismicio/types"
import { isFilled } from "@prismicio/helpers"
import clsx from "clsx"

import { BoundedBox } from "../components/BoundedBox"
import { Blockquote } from "../components/Blockquote"
import { Text } from "../components/Text"
import PageDataBodyCallToAction from "../slices/PageDataBodyCallToAction"
import { SEO } from "../components/SEO"
import { DescriptionCard } from "../components/DescriptionCard"
import { RichText } from "../components/RichText"
import { useSiteSettings } from "../hooks/useSiteSettings"
import { usePracticeAreaSettings } from "../hooks/usePracticeAreaSettings"

import type { PracticeAreaTemplateQuery } from "../graphql.gen"
import PageDataBodyBlogHighlights from "../slices/PageDataBodyBlogHighlights"
import { jsonParseSafe } from "../lib/jsonParseSafe"

interface ImageWithCardProps {
	image?: IGatsbyImageData
	imageAlt?: string
	heading?: string
}

const ImageWithCard = ({ image, heading, imageAlt }: ImageWithCardProps) => {
	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner
				maxWidth="narrow"
				className={clsx(
					"grid lg:grid-cols-[1fr,calc(414px-62px)]",
					"xl:grid-cols-[1fr,calc(507px-62px)"
				)}
			>
				{image && (
					<div
						className={clsx(
							"-mx-[26px] lg:mx-0",
							"md:col-start-1 md:row-start-1"
						)}
					>
						<div
							className={clsx(
								"aspect-w-[320] aspect-h-[284]",
								"md:aspect-w-[647] md:aspect-h-[432]",
								"3xl:aspect-w-[647] 3xl:aspect-h-[375]"
							)}
						>
							<div className="bg-gray-93">
								<GatsbyImage
									image={image}
									alt={imageAlt ?? ""}
									objectFit="cover"
									objectPosition="center"
									className="w-full h-full"
								/>
							</div>
						</div>
					</div>
				)}

				<DescriptionCard
					className={clsx(
						"-mt-24 md:-mt-0",
						"-mr-[26px] lg:-mr-0",
						"ml-5 md:ml-0 lg:-ml-[62px]",
						"isolate",
						"max-w-[414px] lg:max-w-none lg:min-w-[414px] xl:min-w-[507px]",
						"justify-self-end md:self-end",
						"md:col-start-1 lg:col-start-2",
						"md:row-start-1"
					)}
				>
					<Text asChild variant="heading5" uppercase>
						<p>Practice Areas</p>
					</Text>

					{heading && (
						<Text
							asChild
							variant="practiceAreaHeading"
							className="mt-5 md:mt-6 lg:mt-7 xl:mt-8"
						>
							<h1>{heading}</h1>
						</Text>
					)}

					<div
						className={clsx(
							"w-8 md:w-12 lg:w-[72px]",
							"h-[5px] md:h-2 lg:h-[11px]",
							"bg-red",
							"mt-4 md:mt-5 lg:mt-7 xl:mt-8"
						)}
					/>
				</DescriptionCard>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

interface RichTextAndQuoteProps {
	richText?: RichTextField
	quote?: string
	quotee?: string
}

const RichTextAndQuote = ({
	richText,
	quote,
	quotee,
}: RichTextAndQuoteProps) => {
	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner
				className={clsx(
					"grid gap-y-12 md:gap-y-20",
					"lg:grid-cols-2",
					"lg:items-center",
					"lg:gap-x-12 xl:gap-x-20 2xl:gap-x-32"
				)}
			>
				{isFilled.richText(richText) && (
					<RichText field={richText} className="max-w-[820px]" />
				)}

				{quote && (
					<Blockquote
						variant="red"
						quotee={quotee}
						className="max-w-[480px] mx-auto"
					>
						{quote}
					</Blockquote>
				)}
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

const PracticeAreaTemplate = ({
	data,
}: PageProps<PracticeAreaTemplateQuery>) => {
	const practiceAreaSettings = usePracticeAreaSettings()
	const practiceArea = data.prismicPracticeArea

	const relatedPosts =
		practiceArea?.data.related_blog_posts
			?.filter((node) => Boolean(node?.post?.document))
			.map((node) => {
				const postDoc = node?.post?.document
				if (postDoc && postDoc?.__typename !== "PrismicBlogPost") {
					throw new Error("Unreachable!")
				}

				const categoryDoc = postDoc?.data.category?.document
				if (categoryDoc && categoryDoc.__typename !== "PrismicBlogCategory") {
					throw new Error("Unreachable")
				}

				return {
					category: categoryDoc?.data.name?.text,
					href: postDoc?.url,
					title: postDoc?.data.title?.text,
					imageAlt: postDoc?.data.image?.alt,
					image: postDoc?.data.image?.gatsbyImageData as IGatsbyImageData,
				}
			}) ?? []

	const [firstPost, secondPost] = relatedPosts

	return (
		<>
			<ImageWithCard
				image={practiceArea?.data.image?.gatsbyImageData as IGatsbyImageData}
				imageAlt={practiceArea?.data.image?.alt}
				heading={practiceArea?.data.title?.text}
			/>
			<RichTextAndQuote
				richText={practiceArea?.data.rich_text?.richText}
				quote={practiceArea?.data.quote?.text}
				quotee={practiceArea?.data.quotee}
			/>

			<PageDataBodyCallToAction
				buttonHref={practiceAreaSettings.ctaHref}
				buttonText={practiceAreaSettings.ctaLinkText}
				colorTheme={practiceAreaSettings.ctaColorTheme}
				heading={practiceAreaSettings.ctaHeading}
				text={practiceAreaSettings.ctaText}
				accordionText={[]}
			/>

			{relatedPosts.length > 0 && (
				<PageDataBodyBlogHighlights
					heading="The Blog"
					renderLeft={
						firstPost ? (
							<PageDataBodyBlogHighlights.Post {...firstPost} />
						) : null
					}
					renderRight={
						secondPost ? (
							<PageDataBodyBlogHighlights.Post {...secondPost} />
						) : null
					}
				/>
			)}
		</>
	)
}

export const Head = ({
	data,
	location,
}: HeadProps<PracticeAreaTemplateQuery>) => {
	const settings = useSiteSettings()
	const practiceArea = data.prismicPracticeArea
	const structuredData = jsonParseSafe(practiceArea?.data.structured_data?.text)

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle={practiceArea?.data.title?.text}
			pathname={location.pathname}
			structuredData={structuredData}
			meta={{
				description: practiceArea?.data.meta_description?.text,
				title: practiceArea?.data.meta_title?.text,
			}}
			twitter={{
				username: settings.twitter.username,
				cardImageUrl:
					practiceArea?.data.twitter_card?.url ?? settings.twitter.cardImageUrl,
			}}
			openGraph={{
				cardImageUrl:
					practiceArea?.data.open_graph_image?.url ??
					settings.openGraph.cardImageUrl,
			}}
		/>
	)
}

/**
 * When a Prismic preview session is active, `withPrismicPreview` will
 * automatically provide your template with updated preview content. As editors
 * edit and save content in the Prismic writing room, the page will
 * automatically refresh to display the edited content.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicpreview
 */
export default withPrismicPreview(PracticeAreaTemplate)

export const query = graphql`
	query PracticeAreaTemplate($id: String!) {
		prismicPracticeArea(id: { eq: $id }) {
			# The _previewable field enables previews for this document.
			_previewable
			data {
				meta_description {
					text
				}
				meta_title {
					text
				}
				structured_data {
					text
				}
				open_graph_image {
					url(imgixParams: { width: 1200, height: 630, q: 75 })
				}
				twitter_card {
					url(imgixParams: { width: 800, height: 418, q: 75 })
				}

				title {
					text
				}
				image {
					alt
					gatsbyImageData(
						width: 1280
						layout: FULL_WIDTH
						sizes: "(min-width: 90rem) 58rem, (min-width: 64rem) 55vw, 100vw"
					)
				}
				quote {
					text
				}
				quotee
				rich_text {
					richText
				}
				related_blog_posts {
					post {
						document {
							__typename
							... on PrismicBlogPost {
								_previewable
								url
								data {
									title {
										text
									}
									image {
										alt
										gatsbyImageData(
											layout: FULL_WIDTH
											width: 800
											sizes: "(min-width: 100rem) 45rem, (min-width: 64rem) 40vw, 100vw"
										)
									}
									category {
										document {
											__typename
											... on PrismicBlogCategory {
												_previewable
												data {
													name {
														text
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
