/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import clsx from "clsx"
import useEmblaCarousel from "embla-carousel-react"

import { BoundedBox } from "../components/BoundedBox"
import { HoverCard } from "../components/HoverCard"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { Text } from "../components/Text"
import { times } from "../lib/times"

import type {
	AllPracticeAreasQuery,
	PageDataBodyPracticeAreasFragment,
	PracticeAreaDataFragment,
} from "../graphql.gen"
import * as styles from "./PracticeAreas.module.css"
import allPracticeAreasJpg from "../assets/all-practice-areas.jpg"

type Props = ReturnType<typeof mapDataToProps>

function mapPracticeAreaData(item: PracticeAreaDataFragment) {
	const title = item.data.embed_label || item.data.title?.text || ""

	return {
		href: item.url,
		title,
		image: item.data.image?.gatsbyImageData as IGatsbyImageData,
		imageAlt: item.data.image?.alt,
	}
}

function useAllTopLevelPracticeAreas() {
	const result = useStaticQuery<AllPracticeAreasQuery>(graphql`
		query AllPracticeAreas {
			allPrismicPracticeArea {
				nodes {
					...PracticeAreaData
				}
			}
		}
	`)

	return result.allPrismicPracticeArea.nodes
		.filter((pa) => !pa.data.parent?.uid)
		.map(mapPracticeAreaData)
		.sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0))
}

type ContentProps = Pick<Props, "practiceAreas" | "showViewAllLink">
const Content = ({ practiceAreas, showViewAllLink }: ContentProps) => {
	return (
		<>
			{practiceAreas.map((area) => {
				if (!area.title || !area.href) return null

				return (
					<HoverCard
						key={area.title}
						href={area.href}
						title={area.title}
						image={area.image}
						imageAlt={area.imageAlt}
					/>
				)
			})}

			{showViewAllLink && (
				<HoverCard
					href="/practice-areas/"
					title="View All"
					variant="red"
					renderImage={
						<img
							src={allPracticeAreasJpg}
							loading="lazy"
							decoding="async"
							className="object-cover object-bottom w-full h-full"
							width={1000}
							height={1500}
							alt=""
						/>
					}
				/>
			)}
		</>
	)
}

const Carousel = ({
	practiceAreas,
	showViewAllLink,
	className,
	...props
}: ContentProps & React.ComponentPropsWithoutRef<"div">) => {
	const [currIdx, setCurrIdx] = React.useState(0)
	const [emblaRef, emblaApi] = useEmblaCarousel()

	const numDots = showViewAllLink
		? practiceAreas.length + 1
		: practiceAreas.length

	function handleDotClick(idx: number) {
		if (!emblaApi) return

		emblaApi.scrollTo(idx)
	}

	React.useEffect(() => {
		if (!emblaApi) return

		function onSlideChange() {
			if (!emblaApi) return

			setCurrIdx(emblaApi.selectedScrollSnap())
		}

		emblaApi.on("select", onSlideChange)

		return () => {
			emblaApi.off("select", onSlideChange)
		}
	}, [emblaApi])

	return (
		<div className={clsx("flex flex-col", className)} {...props}>
			<div
				className={clsx("pl-[26px]", "-ml-[26px]", styles.embla)}
				ref={emblaRef}
			>
				<div className={styles.emblaContainer}>
					<Content
						practiceAreas={practiceAreas}
						showViewAllLink={showViewAllLink}
					/>
				</div>
			</div>

			<div className="self-center grid grid-flow-col gap-2.5 mt-6">
				{times(numDots, (idx) => {
					const practiceArea = practiceAreas[idx]

					return (
						<button
							key={idx}
							onClick={() => handleDotClick(idx)}
							className={clsx(
								"w-2.5 h-2.5",
								idx === currIdx ? "bg-red" : "bg-gray-72",
								"transition",
								"rounded-full"
							)}
						>
							<span className="sr-only">
								{practiceArea ? `View ${practiceArea.title}` : "Go to View All"}
							</span>
						</button>
					)
				})}
			</div>
		</div>
	)
}

const Grid = ({
	practiceAreas,
	showViewAllLink,
	className,
	...props
}: ContentProps & React.ComponentPropsWithoutRef<"div">) => {
	return (
		<div
			className={clsx(
				"md:pl-[26px] lg:pl-0",
				"md:grid-cols-2 lg:grid-cols-3",
				"gap-9 md:gap-12 xl:gap-x-[84px] xl:gap-y-[88px]",
				className
			)}
			{...props}
		>
			<Content
				practiceAreas={practiceAreas}
				showViewAllLink={showViewAllLink}
			/>
		</div>
	)
}

export const PageDataBodyPracticeAreas = ({
	heading,
	showViewAllLink,
	practiceAreas,
	isCarousel,
}: Props) => {
	return (
		<BoundedBox.Outer className="bg-white">
			<BoundedBox.Inner className="grid gap-y-8 md:gap-y-12 xl:gap-y-14">
				{heading && (
					<Text asChild variant="heading2" uppercase>
						<h2>{heading}</h2>
					</Text>
				)}

				{isCarousel && (
					<Carousel
						practiceAreas={practiceAreas}
						showViewAllLink={showViewAllLink}
						className="md:hidden"
					/>
				)}

				<Grid
					practiceAreas={practiceAreas}
					showViewAllLink={showViewAllLink}
					className={clsx(isCarousel ? "hidden md:grid" : "grid")}
				/>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyPracticeAreasFragment>) {
	const hasItems = data.items.length >= 0

	const practiceAreas = hasItems
		? // Safe to call a hook here; we're technically inside of a component.
		  // `mapDataToProps` is called at render-time.
		  // eslint-disable-next-line react-hooks/rules-of-hooks
		  useAllTopLevelPracticeAreas()
		: data.items.map((item) => {
				const doc = item.practice_area?.document

				if (doc?.__typename !== "PrismicPracticeArea") {
					throw new Error(
						"Did not receive PracticeArea document when expected!"
					)
				}

				return mapPracticeAreaData(doc)
		  })

	return {
		heading: data.primary.heading?.text,
		showViewAllLink: data.primary.show_view_all_link ?? false,
		practiceAreas,
		isCarousel: data.primary.carousel_on_mobile ?? false,
	}
}

export const fragment = graphql`
	fragment PageDataBodyPracticeAreas on PrismicPageDataBodyPracticeAreas {
		primary {
			heading {
				text
			}
			show_view_all_link
			carousel_on_mobile
		}
		items {
			practice_area {
				document {
					__typename
					... on PrismicPracticeArea {
						...PracticeAreaData
					}
				}
			}
		}
	}

	fragment PracticeAreaData on PrismicPracticeArea {
		_previewable
		url
		data {
			title {
				text
			}
			parent {
				uid
			}
			embed_label
			image {
				gatsbyImageData(
					width: 477
					sizes: "(min-width: 90rem) 30rem, (min-width: 64rem) 30vw, (min-width: 48rem) 45vw, 90vw"
					layout: FULL_WIDTH
				)
				alt
			}
		}
	}
`

export default PageDataBodyPracticeAreas
