import * as React from "react"
import clsx from "clsx"
import { type GetVariants, twix } from "@asyarb/twix"
import { Slot, type SlotProps } from "@radix-ui/react-slot"

const text = twix({
	base: "",
	variants: {
		fontFamily: {
			sans: "font-sans",
			serif: "font-serif",
		},
		uppercase: {
			true: "uppercase",
		},
		variant: {
			heading1:
				"text-44 md:text-54 lg:text-66 2xl:text-79 font-semibold leading-1",
			heading2:
				"text-24 md:text-28 lg:text-37 2xl:text-44 font-semibold leading-1_15",
			heading3:
				"text-18 md:text-20 lg:text-24 2xl:text-28 font-extrabold leading-1_3",
			heading4:
				"text-14 md:text-15 lg:text-18 2xl:text-20 leading-1_5 font-semibold",
			heading5:
				"text-10 md:text-11 lg:text-13 2xl:text-15 leading-1_5 font-semibold",
			heading6:
				"text-16 md:text-18 lg:text-20 2xl:text-22 font-bold leading-1_35",

			paragraph1: "text-14 md:text-15 lg:text-16 2xl:text-18 leading-1_35",
			paragraph2: "text-10 2xl:text-11 leading-1_3",

			sitemapLink: "text-14 md:text-15 lg:text-16 leading-1_35 font-semibold",

			listItem: "text-16 md:text-18 lg:text-20 2xl:text-22 leading-1_3",
			blockquote:
				"text-37 md:text-44 lg:text-54 2xl:text-70 leading-1 font-extrabold",
			blockquotee: "text-10 md:text-13 lg:text-15 leading-1_15 font-semibold",

			richTextLargeParagraph:
				"text-16 md:text-18 lg:text-20 2xl:text-22 leading-1_35",

			footerParagraph: "text-18 leading-1_35",
			footerHeading1: "text-20 font-bold leading-1",
			footerHeading2: "text-20 font-semibold leading-1",
			footerCopyright: "text-12 leading-1_35",

			headerPrimaryItem: "text-21 font-extrabold leading-1_15",
			headerSecondaryItem: "text-20 font-semibold leading-1",
			headerSubItem: "text-15 font-semibold leading-1_15",

			paginationText: "text-18 md:text-20 leading-1_25 font-extrabold",

			notFoundHeading:
				"text-70 font-semibold md:text-90 lg:text-100 xl:text-170",
			pageIntro: "text-23 font-extrabold md:text-44 xl:text-62",

			blogTitle:
				"text-35 md:text-37 lg:text-44 2xl:text-48 leading-1_15 font-extrabold",
			authorName: "text-18 md:text-20 leading-1_15 font-extrabold",
			authorTitle: "text-14 md:text-15 leading-1_5 font-semibold",
			authorBio: "text-14 leading-1_35",

			practiceAreaHeading:
				"text-29 md:text-35 lg:text-44 xl:text-54 2xl:text-66 font-extrabold leading-1_15",

			formLabel: "text-18 lg:text-20 font-extrabold leading-1_15",
			formSuccessHeading: "text-30 lg:text-35 font-extrabold leading-1_25",
			formSuccessText: "text-17 lg:text-20 font-semibold leading-1_35",

			animatedIntro:
				"text-37 md:text-46 lg:text-58 2xl:text-79 font-semibold leading-1",

			ctaParagraph: "text-18 md:text-20 lg:text-22 2xl:text-24 leading-1_35",
			richTextCTAHeading:
				"text-24 md:text-28 lg:text-37 2xl:text-60 font-semibold leading-1_2",
		},
	},
	defaultVariants: {
		fontFamily: "sans",
		uppercase: false,
	},
	compoundVariants: [
		{
			variant: "heading4",
			uppercase: true,
			class: "tracking-[.23px]",
		},
	],
})

interface StaticTextProps extends SlotProps {
	asChild?: boolean
}

export type TextProps = StaticTextProps & GetVariants<typeof text>

export const Text = React.forwardRef<HTMLDivElement, TextProps>(
	({ asChild, className, variant, fontFamily, uppercase, ...props }, ref) => {
		const Component = asChild ? Slot : "div"

		return (
			<Component
				ref={ref}
				className={clsx(className, text({ variant, fontFamily, uppercase }))}
				{...props}
			/>
		)
	}
)

Text.displayName = "Text"
