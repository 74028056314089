import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { BoundedBox } from "../components/BoundedBox"
import type { PageDataBodyHtmlEmbedFragment } from "../graphql.gen"
import { RawHTMLContent } from "../components/RawHTMLContent"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyHtmlEmbed = ({ html }: Props) => {
	if (!html) return null

	return (
		<BoundedBox className="bg-white">
			<RawHTMLContent className="max-w-[820px] mx-auto w-full" html={html} />
		</BoundedBox>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyHtmlEmbedFragment>) {
	return {
		html: data.primary.html?.text,
	}
}

export const fragment = graphql`
	fragment PageDataBodyHtmlEmbed on PrismicPageDataBodyHtmlEmbed {
		primary {
			html {
				text
			}
		}
	}
`

export default PageDataBodyHtmlEmbed
