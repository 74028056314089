import { PrismicRichText } from "@prismicio/react"
import * as Collapsible from "@radix-ui/react-collapsible"
import React from "react"
import { Text } from "./Text"
import { MinusIcon } from "./MinusIcon"
import { PlusIcon } from "./PlusIcon"
import clsx from "clsx"
import { isFilled } from "@prismicio/helpers"
import { RichTextField } from "@prismicio/types"

export interface AccordionProps {
	title: string | undefined
	description: RichTextField
}

export const Accordion = ({ title, description }: AccordionProps) => {
	const [open, setOpen] = React.useState(false)

	return (
		<Collapsible.Root
			open={open}
			onOpenChange={setOpen}
			className={clsx(
				"flex flex-col border-b-2 pb-8 transition-all",
				open ? "border-red" : "border-gray-87"
			)}
		>
			<Collapsible.Trigger>
				<div className="flex justify-between items-center">
					{title && <Text variant="heading6">{title}</Text>}

					{open ? (
						<MinusIcon className="text-black w-4" />
					) : (
						<PlusIcon className="text-black w-4" />
					)}
				</div>
			</Collapsible.Trigger>

			<Collapsible.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden transition-all">
				{isFilled.richText(description) && (
					<div className="mt-10">
						<PrismicRichText field={description} />
					</div>
				)}
			</Collapsible.Content>
		</Collapsible.Root>
	)
}
