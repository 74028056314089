import clsx from "clsx"
import { PrismicLink } from "@prismicio/react"
import { DescriptionCard, type DescriptionCardProps } from "./DescriptionCard"
import { type IGatsbyImageData } from "gatsby-plugin-image"
import { HoverZoomImage, type HoverZoomImageProps } from "./HoverZoomImage"
import { ConditionalWrap } from "./ConditionalWrap"
import * as styles from "./Card.module.css"

export interface CardProps
	extends Omit<DescriptionCardProps, "size">,
		Pick<HoverZoomImageProps, "renderImage"> {
	image?: IGatsbyImageData
	imageAlt?: string
}

interface ExtraColorVariants {
	imageBg: string
	disableHover: boolean
}

const extraColorVariants: Record<
	NonNullable<DescriptionCardProps["color"]>,
	ExtraColorVariants
> = {
	gray: { imageBg: "bg-black", disableHover: true },
	black: { imageBg: "bg-gray-20", disableHover: false },
}

export const Card = ({
	subheading,
	heading,
	richText,
	href,
	color = "black",
	className,
	image,
	imageAlt,
	renderImage,
	linkText,
	...props
}: CardProps) => {
	const colorVariants = extraColorVariants[color]

	return (
		<div className={clsx("flex flex-col", className)} {...props}>
			<div
				className={clsx(
					"aspect-w-[271] aspect-h-[183]",
					"lg:aspect-w-[352] lg:aspect-h-[238]",
					styles.image,
				)}
			>
				<ConditionalWrap
					condition={Boolean(href)}
					wrap={(children) => <PrismicLink href={href}>{children}</PrismicLink>}
				>
					<HoverZoomImage
						image={image}
						alt={imageAlt}
						className={colorVariants.imageBg}
						renderImage={renderImage}
						disable={colorVariants.disableHover}
					/>
				</ConditionalWrap>
			</div>

			<DescriptionCard
				subheading={subheading}
				heading={heading}
				richText={richText}
				href={href}
				className={clsx("grow", styles.descriptionCard)}
				size="small"
				color={color}
				linkText={linkText}
			/>
		</div>
	)
}
