import * as React from "react"
import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import clsx from "clsx"

import { BoundedBox } from "../components/BoundedBox"
import { Very } from "../components/Very"
import { Text, TextProps } from "../components/Text"
import { Law } from "../components/Law"

import type { PageDataBodyAnimatedIntroFragment } from "../graphql.gen"

const LAW_WORD = "law"

interface WordProps extends Omit<TextProps, "variant"> {
	children: string
}

const Word = ({ children, className, ...props }: WordProps) => {
	return (
		<Text
			variant="animatedIntro"
			uppercase
			className={clsx("text-red whitespace-nowrap", className)}
			{...props}
		>
			{children}
		</Text>
	)
}

const Words = ({ words }: Pick<Props, "words">) => {
	const [activeIdx, setActiveIdx] = React.useState(0)

	// We need the longest word so we know how to size our "clip container"
	const longestWord = words.reduce((a, b) => (a.length >= b.length ? a : b), "")

	React.useEffect(() => {
		const interval = setInterval(
			() =>
				setActiveIdx((prevIdx) => {
					if (prevIdx === words.length - 1) {
						return 0
					}

					return prevIdx + 1
				}),
			2000,
		)

		return () => clearInterval(interval)
	}, [words.length])

	return (
		<div
			className={clsx(
				"relative overflow-hidden",
				"w-full md:w-1/2",
				"text-center md:text-left",
				"py-1 lg:py-1.5",
			)}
		>
			<Word className="invisible">{longestWord}</Word>

			{words.map((word, idx) => {
				const isActive = activeIdx === idx
				// We're the next element to show if incrementing the idx is "us",
				// OR we're at the end of the array and we're the first element.
				const isNext =
					activeIdx + 1 === idx || (activeIdx === words.length - 1 && idx === 0)

				const isLawWord = word === LAW_WORD
				const classNames = clsx(
					"absolute",
					"inset-y-1 lg:inset-y-1.5 inset-x-0",
					"transition duration-500 ease-in-out",
					"will-change-transform transform-gpu",
					isNext &&
						"!-translate-y-[calc(100%+8px)] lg:-translate-y-[calc(100%+12px)] !duration-[0ms]",
					isActive && "translate-y-0 delay-300",
					!isActive &&
						"translate-y-[calc(100%+8px)] lg:translate-y-[calc(100%+12px)]",
				)

				if (isLawWord) {
					return (
						<Law
							key={LAW_WORD}
							className={clsx(
								classNames,
								"h-[25px] md:h-[33px] lg:h-10 2xl:h-14",
								"absolute",
								"mx-auto md:mr-0 md:ml-3 lg:ml-4",
							)}
						>
							{longestWord}
						</Law>
					)
				}

				return (
					<Word key={word} className={clsx(classNames, "inset-x-0")}>
						{word}
					</Word>
				)
			})}
		</div>
	)
}

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyAnimatedIntro = ({ words }: Props) => {
	return (
		<BoundedBox.Outer
			className="bg-white relative min-h-[120vh] -mb-[60vh]"
			paddingY="animatedIntro"
			data-animated-intro
		>
			<BoundedBox.Inner
				className={clsx(
					"flex",
					"flex-col md:flex-row",
					"items-center",
					"justify-center",
					"space-y-4 md:space-y-0",
					"md:space-x-3 2xl:space-x-5",
					"min-h-[60vh] fixed",
					"left-1/2 -translate-x-1/2",

					// Kinda hacky, but the top offset must match the height of the header.
					// There are other ways to parallax scroll this intro, but involve
					// reading the screensize at runtime. This ultimately feels simpler but
					// a bit more tedious to maintain.
					"top-[73px] md:top-[105px] lg:top-[148.3px]",
				)}
			>
				<div className="flex justify-end md:w-1/2">
					<Very
						withAnimation={false}
						className="h-[25px] md:h-[33px] lg:h-10 2xl:h-14 shrink-0"
					/>
				</div>

				<Words words={words} />
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyAnimatedIntroFragment>) {
	const words = data.items
		.map((item) => item.word?.text)
		.filter(Boolean) as string[]

	words.push(LAW_WORD)

	return {
		words,
	}
}

export const fragment = graphql`
	fragment PageDataBodyAnimatedIntro on PrismicPageDataBodyAnimatedIntro {
		items {
			word {
				text
			}
		}
	}
`

export default PageDataBodyAnimatedIntro
