export function jsonParseSafe<T = Record<string, unknown>>(
	data: string | undefined
): T | undefined {
	if (!data) return

	try {
		return JSON.parse(data)
	} catch (error) {
		return
	}
}
