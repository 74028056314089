import * as React from "react"
import { RECAPTCHA_SITE_KEY } from "../constants"

export function useLoadGoogleRecaptcha() {
	React.useEffect(() => {
		if (typeof window === "undefined") return

		const script = document.createElement("script")
		script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
		script.defer = true

		document.head.appendChild(script)
	}, [])
}
