import * as React from "react"
import clsx from "clsx"
import { type IGatsbyImageData } from "gatsby-plugin-image"
import { Link, navigate } from "gatsby"
import { BoundedBox } from "../components/BoundedBox"
import { Text } from "../components/Text"
import { ThickChevronButton } from "../components/ThickChevronButton"
import { ThinChevronIcon } from "../components/ThinChevronIcon"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { Card } from "../components/Card"

import type { BlogPageQuery } from "../graphql.gen"
import blogDefaultUrl from "../assets/blog-default.jpg"
import { CardGrid } from "../components/CardGrid"
import { paginationRange } from "../lib/pagination"

type Props = ReturnType<typeof mapDataToProps>

interface LocationState {
	category?: string
	hasUserNavigated?: boolean
}

export const PageDataBodyAllBlogPosts = ({
	posts,
	categories,
	totalPages,
	currentPage,
	paginationIndexPath,
	location,
}: Props) => {
	const containerRef = React.useRef<HTMLDivElement>(null)
	const locationState = location?.state as LocationState | undefined

	const defaultCategory =
		locationState?.category ??
		location?.pathname.split("/").filter(Boolean).at(1)

	const pagination = paginationRange({
		total: totalPages,
		page: currentPage,
	})

	// Page numbers to show based on the `currentPage`.

	function navigateToCategoryPage(e: React.ChangeEvent<HTMLSelectElement>) {
		const category = e.target.value
		if (category === "all") {
			return navigate("/blog/", { state: { hasUserNavigated: true } })
		}

		if (categories.some((value) => value.uid === category)) {
			return navigate(`/blog/${category}/`, {
				state: { category, hasUserNavigated: true },
			})
		}
	}

	React.useEffect(() => {
		if (!locationState?.hasUserNavigated) return

		containerRef.current?.scrollIntoView()
	}, [locationState?.hasUserNavigated])

	return (
		<BoundedBox.Outer
			className={clsx(
				"bg-white",
				// Offset of the sticky header
				"scroll-mt-20 md:scroll-mt-32 lg:scroll-mt-40"
			)}
			ref={containerRef}
		>
			<BoundedBox.Inner className="flex flex-col">
				<div className="space-y-4">
					<Text variant="heading3">Filter by Category</Text>

					<div className="relative max-w-sm">
						<select
							name="category-filter"
							className={clsx(
								"w-full h-12",
								"px-5 py-0",
								"font-sans font-semibold uppercase text-14 leading-1",
								"border-2 border-black",
								"focus:border-red focus:ring-0 transition"
							)}
							onChange={navigateToCategoryPage}
							defaultValue={defaultCategory}
						>
							<option value="all" className="normal-case">
								All
							</option>
							{categories.map((c) => (
								<option key={c.uid} value={c.uid} className="normal-case">
									{c.name}
								</option>
							))}
						</select>

						<ThinChevronIcon className="absolute w-4 -translate-y-1/2 pointer-events-none fill-white right-5 top-1/2" />
					</div>
				</div>

				<CardGrid>
					{posts.map((post) => (
						<CardGrid.Item key={post.title}>
							<Card
								subheading={post.category}
								heading={post.title}
								richText={post.summary}
								href={post.href}
								image={post.image}
								imageAlt={post.imageAlt}
								linkText="Read Article"
								renderImage={
									!post.image ? (
										<img
											src={blogDefaultUrl}
											loading="lazy"
											decoding="async"
											className="object-cover object-center w-full h-full"
											alt=""
											width={1600}
											height={1073}
										/>
									) : undefined
								}
								className="h-full"
							/>
						</CardGrid.Item>
					))}
				</CardGrid>

				<div className="flex items-center mx-auto mt-12 space-x-2.5 sm:space-x-3.5">
					<ThickChevronButton
						className={clsx(
							"rotate-180",
							currentPage === 1 && "invisible pointer-events-none"
						)}
						label="Previous blog posts"
						href={
							currentPage === 2
								? paginationIndexPath
								: paginationIndexPath + (currentPage - 1)
						}
						state={{ hasUserNavigated: true }}
					/>

					{pagination.map((num, idx) => {
						if (num === "dots")
							return (
								<span className="mb-1.5 self-end" key={num + idx}>
									...
								</span>
							)

						const href =
							num === 1 ? paginationIndexPath : paginationIndexPath + num

						return (
							<Text
								variant="paginationText"
								key={num}
								className={clsx(
									"flex items-center justify-center rounded-full",
									"w-9 h-9 md:w-12 md:h-12",
									num === currentPage
										? "bg-black text-white"
										: "text-black hover:text-red transition"
								)}
							>
								<Link to={href} state={{ hasUserNavigated: true }}>
									{num}
								</Link>
							</Text>
						)
					})}

					<ThickChevronButton
						label="Next blog posts"
						className={clsx(
							currentPage === totalPages && "invisible pointer-events-none"
						)}
						href={paginationIndexPath + (currentPage + 1)}
						state={{ hasUserNavigated: true }}
					/>
				</div>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export interface AllBlogPostsData {
	__typename: "PrismicPageDataBodyAllBlogPosts"
	posts: BlogPageQuery["allPrismicBlogPost"]["nodes"]
	categories: BlogPageQuery["allPrismicBlogCategory"]["nodes"]
	totalPages: number
	currentPage: number
	paginationIndexPath: string
}

export function mapDataToProps({
	data,
	meta,
}: MapDataToPropsCtx<AllBlogPostsData>) {
	const posts = data.posts.map((node) => {
		const categoryDoc = node.data.category?.document
		if (categoryDoc && categoryDoc.__typename !== "PrismicBlogCategory") {
			throw new Error("Unreachable!")
		}

		return {
			image: node.data.image?.gatsbyImageData as IGatsbyImageData,
			imageAlt: node.data.image?.alt,
			title: node.data.title?.text,
			summary: node.data.summary?.richText,
			href: node.url,
			category: categoryDoc?.data.name?.text,
		}
	})

	return {
		posts,
		categories: data.categories.map((c) => ({
			name: c.data.name?.text,
			uid: c.uid,
		})),
		currentPage: data.currentPage,
		totalPages: data.totalPages,
		paginationIndexPath: data.paginationIndexPath,
		location: meta?.location,
	}
}

export default PageDataBodyAllBlogPosts
