/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { PrismicLink, PrismicRichText } from "@prismicio/react"
import { isFilled } from "@prismicio/helpers"
import clsx from "clsx"

import { BoundedBox } from "./BoundedBox"
import { Text } from "./Text"
import { TwitterIcon } from "./TwitterIcon"
import { FacebookIcon } from "./FacebookIcon"
import { LinkedInIcon } from "./LinkedInIcon"
import { InstagramIcon } from "./InstagramIcon"
import { YelpIcon } from "./YelpIcon"
import { VeryLawLogo } from "./VeryLawLogo"
import { ConditionalWrap } from "./ConditionalWrap"

import type { FooterDataQuery } from "../graphql.gen"

function useFooterData() {
	const result = useStaticQuery<FooterDataQuery>(graphql`
		query FooterData {
			prismicSettings {
				_previewable
				data {
					address {
						text
						html
					}
					contact {
						richText
					}
					navigation_menu {
						...NavigationMenuData
					}
					images {
						image {
							gatsbyImageData(
								width: 150
								layout: CONSTRAINED
								breakpoints: [150]
							)
							alt
						}
						link {
							url
						}
					}

					facebook_url
					instagram_url
					linkedin_url
					yelp_url
					twitter_url
					copyright
				}
			}
		}
	`)

	const data = result.prismicSettings?.data
	const menu = data?.navigation_menu?.document
	if (menu?.__typename !== "PrismicNavigation") {
		throw new Error(
			"Did not receive a PrismicNavigation when we queried for it."
		)
	}

	return {
		contactRichText: data?.contact?.richText,
		address: data?.address?.text,
		addressHTML: data?.address?.html,
		navMenu: menu.data.body.map((item) => ({
			text: item.primary.link_text,
			href: item.primary.link_url?.url,
		})),
		images:
			data?.images?.map((item) => ({
				image: item?.image,
				href: item?.link?.url,
			})) ?? [],
		facebookUrl: data?.facebook_url,
		instagramUrl: data?.instagram_url,
		linkedInUrl: data?.linkedin_url,
		yelpUrl: data?.yelp_url,
		twitterUrl: data?.twitter_url,
		copyright: data?.copyright,
	}
}

interface FooterIconProps {
	href: string
	icon: (props: React.ComponentPropsWithoutRef<"svg">) => JSX.Element
	className?: string
}
const FooterIcon = ({ className, href, icon: Icon }: FooterIconProps) => {
	return (
		<PrismicLink
			href={href}
			className={clsx(className, "text-white hover:text-red transition")}
		>
			<Icon />
		</PrismicLink>
	)
}

export const Footer = () => {
	const data = useFooterData()

	return (
		<>
			<div className="h-8 bg-white isolate" />
			<BoundedBox.Outer
				asChild
				className="text-white bg-black"
				collapsePadding={false}
			>
				<footer>
					<BoundedBox.Inner
						className={clsx(
							"lg:grid lg:grid-cols-[auto,1fr,auto]",
							"lg:gap-y-10",
							"lg:gap-x-20 xl:gap-x-32 2xl:gap-x-40"
						)}
					>
						<VeryLawLogo className="max-w-[322px] lg:col-span-full" />

						<hr className="hidden -mx-5 border-t border-white lg:block lg:col-span-full" />

						<div className="space-y-12 lg:mt-0 mt-14">
							{data.addressHTML && (
								<div
									className="text-18 leading-1_35 font-sans"
									dangerouslySetInnerHTML={{ __html: data.addressHTML }}
								/>
							)}

							{isFilled.richText(data.contactRichText) && (
								<PrismicRichText
									field={data.contactRichText}
									components={{
										paragraph: (props) => (
											<Text
												asChild
												key={props.key}
												variant="footerParagraph"
												className="whitespace-pre-line"
												suppressHydrationWarning
											>
												<p>{props.children}</p>
											</Text>
										),
										hyperlink: (props) => (
											<PrismicLink
												key={props.key}
												className="transition hover:text-red"
												href={props.node.data.url!}
												suppressHydrationWarning
											>
												{props.children}
											</PrismicLink>
										),
									}}
								/>
							)}
						</div>

						<nav
							aria-label="Primary site navigation"
							className="flex flex-col items-start space-y-10 mt-14 lg:mt-0"
						>
							{data.navMenu.map((item) => (
								<PrismicLink
									key={item.text}
									href={item.href!}
									className="transition hover:text-red"
								>
									<Text variant="footerHeading1">{item.text}</Text>
								</PrismicLink>
							))}
						</nav>

						<nav aria-label="Social Media links" className="mt-14 lg:mt-0">
							<Text variant="footerHeading2">Follow Us</Text>

							<div className="flex items-center mt-5 space-x-5">
								{data.facebookUrl && (
									<FooterIcon
										icon={FacebookIcon}
										href={data.facebookUrl}
										className="w-[14px]"
									/>
								)}
								{data.twitterUrl && (
									<FooterIcon
										href={data.twitterUrl}
										icon={TwitterIcon}
										className="w-[27px]"
									/>
								)}
								{data.linkedInUrl && (
									<FooterIcon
										href={data.linkedInUrl}
										icon={LinkedInIcon}
										className="w-[27px]"
									/>
								)}
								{data.instagramUrl && (
									<FooterIcon
										href={data.instagramUrl}
										icon={InstagramIcon}
										className="w-[23px]"
									/>
								)}
								{data.yelpUrl && (
									<FooterIcon
										href={data.yelpUrl}
										icon={YelpIcon}
										className="w-6"
									/>
								)}
							</div>
						</nav>

						<div
							className={clsx(
								"flex flex-wrap space-x-4 items-center",
								"justify-center lg:justify-end",
								"max-w-[80%] lg:max-w-none",
								"mx-auto lg:mx-0",
								"mt-10 lg:mt-0",
								"lg:col-span-full"
							)}
						>
							{data.images.map((image, idx) => (
								<ConditionalWrap
									key={idx}
									condition={Boolean(image.href)}
									wrap={(child) => (
										<PrismicLink
											className="w-[calc(50%-1rem)] lg:w-auto"
											href={image.href!}
										>
											{child}
										</PrismicLink>
									)}
								>
									<GatsbyImage
										image={image.image?.gatsbyImageData as IGatsbyImageData}
										alt={image.image?.alt ?? ""}
										className={clsx(
											image.href ? "w-full" : "w-[calc(50%-1rem)] lg:w-auto"
										)}
										objectFit="contain"
									/>
								</ConditionalWrap>
							))}
						</div>

						<div className="mt-3 lg:col-span-full lg:mt-0 lg:flex lg:justify-between lg:items-end lg:space-x-10">
							<Text asChild variant="footerCopyright" className="shrink-0">
								<p>
									{data.copyright?.replace(
										"{YEAR}",
										new Date(Date.now()).getFullYear().toString()
									)}
								</p>
							</Text>

							<Text
								asChild
								variant="footerCopyright"
								className="max-w-lg mt-7 lg:mt-0 lg:max-w-none lg:text-right"
							>
								<p>
									Website design and development by{" "}
									<a
										href="https://walltowall.com"
										rel="noopener noreferrer nofollow"
										target="_blank"
										className="transition hover:text-red"
									>
										Wall-to-Wall Studios
									</a>
									. This site meets WCAG 2.1 Web Accessibility Standards.
								</p>
							</Text>
						</div>
					</BoundedBox.Inner>
				</footer>
			</BoundedBox.Outer>
		</>
	)
}
